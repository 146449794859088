.Home-section {
    display: flex;
    position: relative;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    transition: all .6s ease-in-out .2s;
    box-shadow: rgb(82, 102, 123) 0px 5px 12px 1px;
    height: calc(90vh - 40px);
    width: 100vw;
}

.Home-section:nth-child(5) {
    box-shadow: rgb(1, 17, 36) 0px 5px 12px 1px;

}


.Section-Active:nth-child(odd) {
    justify-content: flex-end !important;
}

.Section-Active:nth-child(even) {
    justify-content: flex-start !important;
}


.Home-video {
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: filter .2s ease-in-out;
    position: absolute;
    z-index: -99;

}

.Home-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: object-position .5s ease-in-out .3s, filter .2s ease-out;
    position: absolute;
    z-index: -99;

}

.Section-Active>.Home-image,
.Section-Active>.Home-video {
    object-position: center center;
    filter: blur(0px) contrast(100%) brightness(1) !important;
}

.Home-section>.Home-image {
    filter: blur(10px) contrast(130%) brightness(1.2);

}

.Home-section>.Home-video {
    filter: blur(10px) contrast(140%) brightness(1.7);

}

.Home-section:nth-child(odd)>.Home-image {
    object-position: right center;
}

.Home-section:nth-child(even)>.Home-image {
    object-position: left center;
}

.Home-section:nth-child(2)>.Home-image {
    transform: rotateY(180deg);
}

.Home-section:nth-child(3)>.Home-image {
    transform: rotateY(180deg);
}




.Home-text {
    display: flex;
    flex-flow: column;
    justify-content: center;
    width: fit-content;
    height: 100%;
    padding: 5px calc(2% + 3px);
    transition: all .3s ease-in-out, transform .3s ease-in-out, color .3s ease, opacity .3s ease-out .1s, width .2s ease-in-out, height .2s ease-out;
}

.Section-Active>.Home-text {
    opacity: 1 !important;
    transform: none !important;
    max-width: calc(500px + 20vw);
}

.Section-Active:first-child>.Home-text {
    max-width: 100% !important;
    border: none;
}

.Section-Active:last-child>.Home-text {
    max-width: calc(50% + 200px);
    padding-bottom: 10vh;
}

.Section-Active:nth-child(odd)>.Home-text {
    background-color: rgba(255, 255, 255, 0.7) !important;
    color: #0447ab !important;
    border-left: 1px double #1365df30;
}

.Section-Active:nth-child(even)>.Home-text {
    background-color: #1365df8f !important;
    color: white !important;
    border-right: 1px solid rgba(255, 255, 255, 0.2);
}


.Section-Active>.Home-loading{
    height: 100%;
}
.Home-section>.Home-loading{
    height: 100%;
    width: 100%;
}
.Home-loading {
    flex-grow: 1;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    color: #0447ab;
    font-size: calc(20px + .5vw + .3vh);
    font-weight: bold;
    background-color: #0447ab95;
    z-index: -100;

}

.Home-loading>h2 {
    height: 50%;
}

.Home-section:first-child {
    flex-flow: column;
    height: calc(94vh - 20px);
}

.Home-section:first-child>.Home-text {
    max-width: 100%;
    height: fit-content !important;
    color: aliceblue;
    padding: 5px 10vw 5vh 10vw;
    align-items: flex-start;
    bottom: 0 !important;
    transform: translateY(30vw);
}

.Home-section:first-child>.Home-loading {
    width: 100%;
    z-index: -100;
}


.Home-section:nth-child(odd)>.Home-text {
    background-color: rgba(255, 255, 255);
    color: #1365df00;
    align-items: flex-end;
    transform: translateX(-35vw);
    opacity: 0;
}


.Home-section:nth-child(even)>.Home-text {
    background-color: #1365df;
    color: rgba(255, 255, 255, 0);
    align-items: flex-start;
    transform: translateX(35vw);
    opacity: 0;
}

.Home-section:last-child>.Home-text {
    min-width: 50%;
    transform: translateX(-10vw);
}


.Text-title {
    font-size: calc(20px + .7vw + .55vh);
    height: fit-content;
    line-height: calc(20px + .7vw + .55vh);
    margin: 2% 0;
    border-bottom: 3px double red;
    width: fit-content;

}

.Text-paragraph-Wrapper {
    word-wrap: break-word;
}

.Text-paragraph {
    font-size: calc(8px + .6vw + .3vh);
    text-indent: 2em;
    text-align: justify;
    flex-grow: 1;
    margin-bottom: 2%;
}

.Para-in-link {
    text-decoration: underline;
    cursor: pointer;
    font-weight: 700;
}

.Para-ex-link {
    text-decoration: underline;
    cursor: pointer;
    font-weight: 700;
}


.Resolution-text {
    width: 50vw !important;
    max-width: none !important;
    top: 0;
    justify-content: flex-start;
    padding-top: 50px;
}

@media only screen and (max-width: 750px) {
    .Home-text {
        height: auto;
    }

    .Section-Active {
        overflow: hidden;
    }

    .Section-Active:nth-child(2) .Home-text {
        width: 75vw;
    }

    .Section-Active:nth-child(3) .Home-text {
        width: 75vw;
    }

    .Section-Active:nth-child(4) .Home-text {
        width: 75vw;

    }

    .Section-Active:nth-child(5) .Home-text {
        width: 100vw;
    }

    .Section-Active:nth-child(1)>.Home-image {
        object-position: left center;
        animation: backgroundCenterMoveToLeft 23s ease-in infinite;
    }

    .Section-Active:nth-child(2)>.Home-image {
        object-position: left center;
        animation: backgroundZoomToRight 35s ease-in-out infinite;
    }

    .Section-Active:nth-child(3)>.Home-image {
        object-position: left center;
        animation: backgroundCenterMoveHalfToRight 20s ease-in-out infinite;
    }

    .Section-Active:nth-child(4)>.Home-image {
        object-position: left bottom;
        animation: backgroundBotMoveToRight 20s ease-in-out infinite, rotate20 20s ease-in-out infinite;
    }

    .Section-Active:nth-child(5)>.Home-image {
        object-position: left center;
        animation: backgroundCenterMoveToRight 20s ease-in-out infinite;
    }

    .Resolution-text {
        top: calc(50% - 300px);
        width: 100vw !important;
        max-width: none !important;
        height: auto;
        transition: all .3s ease-in-out;
        padding-top: 0;
    }

    .Home-section:nth-child(even) {
        flex-flow: row !important;
    }

    .Home-loading {
        height: 100%;
        width: 100%;
        position: absolute;
        background-color: #0447ab95;
        color: white;
        z-index: 50;
    }

}

@media only screen and (max-width: 950px) and (min-width: 751px) {
    .Section-Active>.Home-text {
        max-width: calc(100vw - 240px);
        height: auto;
    }

    .Section-Active:last-child>.Home-text {
        max-width: calc(100vw - 240px);
    }

    .Section-Active:nth-child(1)>.Home-image {
        object-position: left center;
        animation: backgroundCenterMoveToLeft 23s ease-in infinite;
    }

    .Section-Active:nth-child(2)>.Home-image {
        object-position: left center;
        animation: backgroundZoomToRight 35s ease-in-out infinite;
    }

    .Section-Active:nth-child(3)>.Home-image {
        object-position: left center;
        animation: backgroundCenterMoveHalfToRight 20s ease-in-out infinite;
    }

    .Section-Active:nth-child(4)>.Home-image {
        object-position: left bottom;
        animation: backgroundBotMoveToRight 20s ease-in-out infinite, rotate20 20s ease-in-out infinite;
    }

    .Section-Active:nth-child(5)>.Home-image {
        object-position: left center;
        animation: backgroundCenterMoveToRight 20s ease-in-out infinite;
    }

    .Resolution-text {
        width: calc(100vw - 120px) !important;
        max-width: none !important;
        height: auto;
        transition: all .3s ease-in-out;
        top: calc(50% - 300px);
        padding-top: 0;
    }

    .Home-section:nth-child(even) {
        flex-flow: row !important;
    }

    .Home-loading {
        height: 100%;
        width: 100%;
        position: absolute;
        background-color: #0447ab95;
        color: white;
        z-index: 50;
    }
}



@keyframes backgroundZoomToRight {
    0% {
        object-position: left center;
        transform: scale(1) rotateY(180deg);
        width: 100vw;

    }

    50% {
        object-position: right center;
        transform: scale(2) rotateY(180deg);
        width: 200vw;

    }

    100% {
        object-position: left center;
        transform: scale(1) rotateY(180deg);
        width: 100vw;

    }
}

@keyframes backgroundCenterMoveToLeft {
    0% {
        object-position: right center;
    }

    50% {
        object-position: left center;
    }

    100% {
        object-position: right center;
    }
}

@keyframes backgroundCenterMoveHalfToRight {
    0% {
        object-position: 0% center;
    }

    50% {
        object-position: 50% center;
    }

    100% {
        object-position: 0% center;
    }
}

@keyframes backgroundCenterMoveToRight {
    0% {
        object-position: left center;
    }

    50% {
        object-position: right center;
    }

    100% {
        object-position: left center;
    }
}

@keyframes backgroundBotMoveToRight {
    0% {
        object-position: left bottom;
    }

    50% {
        object-position: right bottom;
    }

    100% {
        object-position: left bottom;
    }
}

@keyframes rotate20 {
    0% {
        transform: rotate(0deg) scale(150%);
    }

    50% {
        transform: rotate(20deg) scale(150%);
    }

    100% {
        transform: rotate(0deg) scale(150%);
    }
}