/* $text-color: #06283D;
$secondary-text-color: #DFF6FF;
$primary-color: #47B5FF;
$secondary-color:  #1363DF;
$bg-color: #DFF6FF; */


.App {
  text-align: center;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-flow: column;
  /* background-color: #06283D; */

}

.App-Screen {
  /* flex-grow: 1; */
  height: calc(96vh - 20px);
}

.App-header {
  z-index: 99;
  position: fixed;
  height: calc(4vh + 20px);
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #06283D;
  transition: background-color 1s ease-out .3s, box-shadow .6s ease-in .5s;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 2px 3px 1px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.App-header_Blur {
  transition: box-shadow .1s ease-in-out 0s, background-color .5s ease-in-out .2s;
  background-color: transparent;
  background-image: none;
  box-shadow: none;
}
.App-header_In-contact {
  background-color: rgb(0, 52, 114);
  box-shadow: rgb(27, 45, 59) 0px 2px 10px 1px, rgb(218, 218, 218) 0px -5px 13px 1px inset;
}



.App-home {
  overflow-y: visible;
  transition: transform .3s ease-in-out .1s;
}

.App-product {
  overflow-y: visible;
  height: 100vh;
}
.App-contact {
  margin-top: calc(4vh + 20px);
  background: #04395b;
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: center;
  position: relative;
}

.Product-Screen {
  height: 100%;
  width: 100vw;
  border-bottom: solid 1px black;
}







.Show-footer {
  max-height: 70vh;
}

.App-footer {
  width: 100vw;
  z-index: 10;
  background-color: #04395b;
  position: fixed;
  bottom: 0;
  overflow-y: visible;
  display: flex;
  justify-content: center;
  box-shadow: rgba(60, 64, 67, 0.7) 0px -2px 3px 1px, rgba(60, 64, 67, 0.5) 0px -1px 3px 1px;
}

.Up_show {
  height: 15vh;
  min-height: 150px;
  transition: height .2s ease-in-out;
}

.Down_hide {
  height: 0px;
  transition: height .2s ease-in-out;
}

.Disable {
  pointer-events: none !important;
  background-color: rgba(0, 68, 170, 0.2) !important;
  outline: 2px solid rgba(0, 68, 170, 0.2) !important;
  border: none !important;
  /* color: rgba(255, 255, 255, 0) !important; */
  box-shadow: none !important;
}

.Disable-display {
  pointer-events: none !important;
  /* opacity: 0 !important; */
}
.Position-top {
  top: 0;
}
.App-pre-footer {
  width: 100vw;
  height: calc(6vh + 20px);
  background: #04395b url(/FooterPattern.png) repeat 35% 10%;
  color: white;
  -webkit-text-stroke: hsl(41.9, 32.8%, 74.3%) 1px;
  padding-top: 10px;
  display: flex;
  justify-content: space-around;
  box-shadow: rgb(1 17 36) 0px 5px 12px 1px inset;
  font-size: calc(10px + .4vw + .2vh);
  z-index: 9;
}


@media only screen and (max-width: 480px) {
  .App-footer {
    flex-flow: column;
    align-items: center;
  }
  .Up_show {
    height: 30vh;
  }
  .App-pre-footer {
    padding-top: 5px;
}
}
@media only screen and (max-width: 780px) and (min-width: 481px) {
  .App-pre-footer {
    padding-top: 5px;
}
}