body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  -webkit-user-select: none;
          user-select: none;
  -webkit-tap-highlight-color: transparent;
}
/* $text-color: #06283D;
$secondary-text-color: #DFF6FF;
$primary-color: #47B5FF;
$secondary-color:  #1363DF;
$bg-color: #DFF6FF; */


.App {
  text-align: center;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-flow: column;
  /* background-color: #06283D; */

}

.App-Screen {
  /* flex-grow: 1; */
  height: calc(96vh - 20px);
}

.App-header {
  z-index: 99;
  position: fixed;
  height: calc(4vh + 20px);
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #06283D;
  transition: background-color 1s ease-out .3s, box-shadow .6s ease-in .5s;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 2px 3px 1px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.App-header_Blur {
  transition: box-shadow .1s ease-in-out 0s, background-color .5s ease-in-out .2s;
  background-color: transparent;
  background-image: none;
  box-shadow: none;
}
.App-header_In-contact {
  background-color: rgb(0, 52, 114);
  box-shadow: rgb(27, 45, 59) 0px 2px 10px 1px, rgb(218, 218, 218) 0px -5px 13px 1px inset;
}



.App-home {
  overflow-y: visible;
  transition: -webkit-transform .3s ease-in-out .1s;
  transition: transform .3s ease-in-out .1s;
  transition: transform .3s ease-in-out .1s, -webkit-transform .3s ease-in-out .1s;
}

.App-product {
  overflow-y: visible;
  height: 100vh;
}
.App-contact {
  margin-top: calc(4vh + 20px);
  background: #04395b;
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: center;
  position: relative;
}

.Product-Screen {
  height: 100%;
  width: 100vw;
  border-bottom: solid 1px black;
}







.Show-footer {
  max-height: 70vh;
}

.App-footer {
  width: 100vw;
  z-index: 10;
  background-color: #04395b;
  position: fixed;
  bottom: 0;
  overflow-y: visible;
  display: flex;
  justify-content: center;
  box-shadow: rgba(60, 64, 67, 0.7) 0px -2px 3px 1px, rgba(60, 64, 67, 0.5) 0px -1px 3px 1px;
}

.Up_show {
  height: 15vh;
  min-height: 150px;
  transition: height .2s ease-in-out;
}

.Down_hide {
  height: 0px;
  transition: height .2s ease-in-out;
}

.Disable {
  pointer-events: none !important;
  background-color: rgba(0, 68, 170, 0.2) !important;
  outline: 2px solid rgba(0, 68, 170, 0.2) !important;
  border: none !important;
  /* color: rgba(255, 255, 255, 0) !important; */
  box-shadow: none !important;
}

.Disable-display {
  pointer-events: none !important;
  /* opacity: 0 !important; */
}
.Position-top {
  top: 0;
}
.App-pre-footer {
  width: 100vw;
  height: calc(6vh + 20px);
  background: #04395b url(/FooterPattern.png) repeat 35% 10%;
  color: white;
  -webkit-text-stroke: hsl(41.9, 32.8%, 74.3%) 1px;
  padding-top: 10px;
  display: flex;
  justify-content: space-around;
  box-shadow: rgb(1 17 36) 0px 5px 12px 1px inset;
  font-size: calc(10px + .4vw + .2vh);
  z-index: 9;
}


@media only screen and (max-width: 480px) {
  .App-footer {
    flex-flow: column;
    align-items: center;
  }
  .Up_show {
    height: 30vh;
  }
  .App-pre-footer {
    padding-top: 5px;
}
}
@media only screen and (max-width: 780px) and (min-width: 481px) {
  .App-pre-footer {
    padding-top: 5px;
}
}
.Header-Blur-layout-Container {
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: -2;
  display: flex;
  align-items: center;
  justify-content: left;
  transition: opacity .2s ease .4s, height .4s ease-in-out .6s;
  position: absolute;
  opacity: .4;

}

.Header-Blur-layout {
  width: 100vw;
  height: 400%;
  z-index: 91;
  position: absolute;
  background: white url("/HeaderPattern.png") repeat 50% 100%;
  background-size: contain;
  transition: opacity .2s ease-in-out .2s, height .2s ease-in-out .4s;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  mix-blend-mode: hard-light;
  align-items: center;
}


.Header-Rainbow {
  width: 1400vw;
  height: 100%;
  background: linear-gradient(30deg, rgba(0, 255, 239, 1) 0%, rgba(14, 255, 0, 1) 6%, rgba(249, 255, 0, 1) 11%, rgba(255, 94, 0, 1) 16%, rgba(255, 0, 0, 1) 21%, rgba(235, 0, 255, 1) 24%, rgba(0, 18, 255, 1) 29%, rgba(0, 254, 255, 1) 33%, rgba(0, 255, 55, 1) 38%, rgba(228, 255, 0, 1) 42%, rgba(255, 145, 0, 1) 47%, rgba(255, 0, 0, 1) 52%, rgba(252, 0, 255, 1) 56%, rgba(8, 0, 255, 1) 61%, rgba(0, 31, 255, 1) 65%, rgba(0, 255, 239, 1) 69%, rgba(0, 222, 48, 1) 73%, rgba(134, 255, 0, 1) 77%, rgba(242, 252, 0, 1) 81%, rgba(250, 125, 0, 1) 86%, rgba(255, 0, 0, 1) 90%, rgba(255, 0, 0, 1) 100%);
  -webkit-animation: rainbowRun 15s ease-in-out 0s infinite;
          animation: rainbowRun 15s ease-in-out 0s infinite;
  -webkit-filter: brightness(200%);
          filter: brightness(200%);
  position: absolute;
  z-index: 90;
  transition: opacity .2s ease-in-out .2s, height .4s ease-out.2s;
  opacity: 1;
}

@-webkit-keyframes rainbowRun {
  0% {
    -webkit-transform: translateX(-1300vw);
            transform: translateX(-1300vw);
    -webkit-transform: none;
            transform: none;
    opacity: 0;
  }

  5% {
    opacity: 1;
  }

  45% {
    opacity: 1;
  }

  50% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 0;
  }

  55% {
    opacity: 1;
  }

  95% {
    opacity: 1;
  }

  100% {
    -webkit-transform: none;
            transform: none;
    opacity: .0;
    -webkit-transform: translateX(-1300vw);
            transform: translateX(-1300vw);
  }
}

@keyframes rainbowRun {
  0% {
    -webkit-transform: translateX(-1300vw);
            transform: translateX(-1300vw);
    -webkit-transform: none;
            transform: none;
    opacity: 0;
  }

  5% {
    opacity: 1;
  }

  45% {
    opacity: 1;
  }

  50% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 0;
  }

  55% {
    opacity: 1;
  }

  95% {
    opacity: 1;
  }

  100% {
    -webkit-transform: none;
            transform: none;
    opacity: .0;
    -webkit-transform: translateX(-1300vw);
            transform: translateX(-1300vw);
  }
}



.App-header_Blur .Header-Blur-layout {
  opacity: 0;
  background-color: transparent;
  transition: opacity .2s ease-in .2s, height .4s ease-in;
  height: 0;
}

.App-header_Blur .Header-Rainbow {
  transition: opacity .2s ease-out .4s, height .2s ease .4s;
  height: 0;
  background: transparent;
  opacity: 0;
}

.App-header_Blur>.Header-Blur-layout-Container {
  height: 0;
  opacity: 0;
  transition: opacity .2s ease-out .6s, height .3s ease .3s;

}

.Header-button {
  font-size: calc(10px + 2vmin);
  width: 120px;
  height: 100%;
  overflow-y: visible;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  position: relative;
}


.Header-button-active>.Button-icon {
  background-color: #3b89ffad;
  max-height: 90%;
  max-width: 40%;
  height: 60px;
  width: 60px;
  color: white;
  border: double .5px rgba(245, 245, 245, 0.2);
  box-shadow: rgba(245, 245, 245, 0.7) 0px 0px 3px 1px, #599afbc0 0px 0px 8px 5px, #438af5d0 0px 0px 20px 5px, #1365dfbc 0px 0px 10px 10px inset, white 0px 0px 30px -1px inset;
}

.App-header_Blur .Button-icon {
  background-color: rgba(255, 255, 255, 0);
  opacity: 0;
  transition: background-color .4s ease-in-out .3s;
}

.Button-icon {
  transition: opacity .5s ease-out .4s, height 0.4s ease-in-out 0s, width .4s ease-in-out 0s, color .7s ease-out 0s, background-color .6s ease-out .1s, box-shadow .5s ease-out .2s;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  color: #1365df;
  opacity: 1;
  z-index: 99;

  box-shadow: rgba(60, 64, 67, 0.3) 0px 0px 3px 1px, rgba(60, 64, 67, 0.15) 0px 0px 3px 1px;

}

.Button-menu {
  font-size: calc(14px + 1.2vmin);
  background-color: #1365df21;
  top: calc(4vh + 20px);
  position: absolute;
  display: flex;
  flex-flow: column;
  color: rgb(255, 255, 255);
  text-shadow: 0px 1px 5px rgba(0, 24, 52, 0.9);
  -webkit-backdrop-filter: blur(5px) contrast(90%) brightness(95%);
          backdrop-filter: blur(5px) contrast(90%) brightness(95%);
  z-index: 99;
}

.Button-menu-container {
  flex-grow: 1;
  padding: 15px;
}

.Button-menu-Outside {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 98, ;
}

.App-menu>.Button-menu {
  width: 400px;
  max-width: 60vw;
  height: calc(96vh - 20px);
  left: 0;
  -webkit-animation: displayFromLeft .2s ease-out;
          animation: displayFromLeft .2s ease-out;
  box-shadow: #3f3f3f2a 3px 3px 5px 1px, #1365df44 5px 3px 10px 0px, #77abff7a 10px 4px 20px 0px, #c5f5ff3a 0px 5px 20px -1px inset;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, .1), #1365df43);

}

@-webkit-keyframes displayFromLeft {
  from {
    left: -450px;
  }

  to {
    left: 0
  }
}

@keyframes displayFromLeft {
  from {
    left: -450px;
  }

  to {
    left: 0
  }
}



.Menu-item {
  padding: 10px 20px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
  cursor: pointer;
  transition: color .4s ease-out, background-color .25s ease-in .1s;

}

.Menu-item:hover {
  color: #06283D;
  background-color: whitesmoke;
  text-shadow: none;

}

.Menu-item:active {
  transition: all .2s ease-in-out;
  color: #1365df;
  background-color: #86abe1;
}

.Menu-item-Container:nth-child(2)>.Menu-item:hover {
  cursor: default;
  background-color: transparent;
}

.Menu-item-Container:nth-child(2)>.Menu-item:active {
  color: #06283D;
  background-color: transparent;
}

.Menu-active {
  font-weight: 900;
  -webkit-backdrop-filter: blur(10px) contrast(40%) brightness(150%);
          backdrop-filter: blur(10px) contrast(40%) brightness(150%);
  box-shadow: 0px 2px 4px 1px #053655c1;
}


.Menu-sub-item-Container {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  transition: height .4s ease-out;
  overflow: visible;
  padding: 5px 0;
}

.Menu-sub-item {
  margin-left: 50px;
  padding: 5px 10px;
  text-align: start;
  cursor: pointer;
  list-style-type: circle;
  transition: color .5s ease-out, background-color .4s ease-in .1s;
  color: white;
}

.Menu-sub-item:hover {
  color: #06283D;
  background-color: whitesmoke;
}

.Menu-sub-item:active {
  transition: all .2s ease-in-out;
  color: #1365df;
  background-color: #86abe1;

}

.Menu-sub-active {
  list-style-type: disc;
  font-weight: 900;
  -webkit-backdrop-filter: blur(50px) brightness(80%);
          backdrop-filter: blur(50px) brightness(80%);
  box-shadow: 0px 2px 4px 1px #053655c1;

}

.App-languages>.Button-menu {
  width: 250px;
  max-width: 30vw;
  right: 0;
  -webkit-animation: displayFromRight .1s ease-out;
          animation: displayFromRight .1s ease-out;
  box-shadow: #3f3f3f2a -3px 3px 5px 1px, #1365df44 -5px 5px 10px 0px, #77abff7a -10px 10px 20px 0px, #c5f5ff3a 0px 0px 20px -1px inset;
  background-image: linear-gradient(to left, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, .1), #1365df43);

}

@-webkit-keyframes displayFromRight {
  from {
    right: -230px;
  }

  to {
    right: 0;
  }
}

@keyframes displayFromRight {
  from {
    right: -230px;
  }

  to {
    right: 0;
  }
}

.Lang-option {
  padding: 5px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: color .5s ease-out, background-color .4s ease-in .1s;
}

.Lang-option:hover {
  color: #06283D;
  background-color: whitesmoke;
}

.Lang-option:active {
  transition: all .2s ease-in-out;
  color: #1365df;
  background-color: #86abe1;
}

.Lang-active::after {
  content: "\2713"
}

.Lang-active {
  font-weight: 900;
  -webkit-backdrop-filter: blur(30px) brightness(120%);
          backdrop-filter: blur(30px) brightness(120%);
  box-shadow: #3f3f3f2a -3px 3px 5px 1px, #1365df44 -5px 5px 10px 0px, #77abff7a -10px 10px 20px 0px, #c5f5ff3a 0px 0px 20px -1px inset;
}

.Menu-pattern-container {
  height: 15vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px -1px 4px 2px #053655c1 inset;
  opacity: .8;
  background-color: #0536555e;


}

.Menu-pattern {
  height: 100%;
  width: 100%;
  background: url("/MenuPattern.jpg") no-repeat 50% 50%;
  -webkit-filter: contrast(120%);
          filter: contrast(120%);
  mix-blend-mode: hard-light;


  background-size: cover;
  -webkit-animation: rotate360 1.3s ease-in .2s;
          animation: rotate360 1.3s ease-in .2s;
  /* animation: changeColor 1.3s ease-in-out; */


}

@-webkit-keyframes changeColor {
  /* 1, 2, 3, 5, 8, 13, 21, 34, 55,  */

  0% {
    mix-blend-mode: hard-light;
  }

  45% {
    mix-blend-mode: color-dodge;
  }

  66% {
    mix-blend-mode: difference;

  }

  79% {
    mix-blend-mode: color-burn;

  }

  87% {
    mix-blend-mode: difference;
  }

  92% {
    mix-blend-mode: luminosity;

  }

  100% {
    mix-blend-mode: hard-light;


  }
}

@keyframes changeColor {
  /* 1, 2, 3, 5, 8, 13, 21, 34, 55,  */

  0% {
    mix-blend-mode: hard-light;
  }

  45% {
    mix-blend-mode: color-dodge;
  }

  66% {
    mix-blend-mode: difference;

  }

  79% {
    mix-blend-mode: color-burn;

  }

  87% {
    mix-blend-mode: difference;
  }

  92% {
    mix-blend-mode: luminosity;

  }

  100% {
    mix-blend-mode: hard-light;


  }
}

@-webkit-keyframes rotate360 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    height: 600px;
    width: 600px;
    mix-blend-mode: hard-light;
  }

  20% {
  }

  35% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    mix-blend-mode: color-dodge;


  }

  40% {
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
    mix-blend-mode: difference;


  }

  60% {
    height: 600px;
    width: 600px;
    -webkit-transform: rotate(45deg) translateY(0%);
            transform: rotate(45deg) translateY(0%);
    mix-blend-mode: color-dodge;

  }

  70% {
    -webkit-transform: rotate(-15deg) translateY(-15%);
            transform: rotate(-15deg) translateY(-15%);
    mix-blend-mode: color-burn;
  }

  79% {
    mix-blend-mode: difference;

  }
 
  95% {
    height: 100%;
    width: 100%;
    -webkit-transform: translateY(5%);
            transform: translateY(5%);
    mix-blend-mode: luminosity;

  }

  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    mix-blend-mode: hard-light;


  }

}

@keyframes rotate360 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    height: 600px;
    width: 600px;
    mix-blend-mode: hard-light;
  }

  20% {
  }

  35% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    mix-blend-mode: color-dodge;


  }

  40% {
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
    mix-blend-mode: difference;


  }

  60% {
    height: 600px;
    width: 600px;
    -webkit-transform: rotate(45deg) translateY(0%);
            transform: rotate(45deg) translateY(0%);
    mix-blend-mode: color-dodge;

  }

  70% {
    -webkit-transform: rotate(-15deg) translateY(-15%);
            transform: rotate(-15deg) translateY(-15%);
    mix-blend-mode: color-burn;
  }

  79% {
    mix-blend-mode: difference;

  }
 
  95% {
    height: 100%;
    width: 100%;
    -webkit-transform: translateY(5%);
            transform: translateY(5%);
    mix-blend-mode: luminosity;

  }

  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    mix-blend-mode: hard-light;


  }

}

@media only screen and (max-width: 300px) {
  .App-menu>.Button-menu {
    width: 100vw;
    max-width: 100vw;
  }

  .Menu-sub-item-Container {
    padding: 0px;
  }

  .Menu-sub-item {
    margin-left: 20px;
    padding: 5px 0px;
  }

  .App-languages>.Button-menu {
    width: 75vw;
    max-width: 100vw;
  }
}

@media only screen and (max-width: 480px) and (min-width: 301px) {


  .App-menu>.Button-menu {
    width: 75vw;
    max-width: 100vw;
  }

  .Menu-sub-item-Container {
    padding: none;
  }

  .Menu-sub-item {
    margin-left: 30px;
    padding: 0px 10px;
    margin-bottom: 10px;
  }

  .Menu-sub-item:last-child {
    margin-bottom: 0;
  }

  .App-languages>.Button-menu {
    max-width: 60vw;
  }
}

@media only screen and (max-width: 798px) and (min-width: 481px) {


  .App-languages>.Button-menu {
    max-width: 40vw;
  }
}
.Home-section {
    display: flex;
    position: relative;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    transition: all .6s ease-in-out .2s;
    box-shadow: rgb(82, 102, 123) 0px 5px 12px 1px;
    height: calc(90vh - 40px);
    width: 100vw;
}

.Home-section:nth-child(5) {
    box-shadow: rgb(1, 17, 36) 0px 5px 12px 1px;

}


.Section-Active:nth-child(odd) {
    justify-content: flex-end !important;
}

.Section-Active:nth-child(even) {
    justify-content: flex-start !important;
}


.Home-video {
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: -webkit-filter .2s ease-in-out;
    transition: filter .2s ease-in-out;
    transition: filter .2s ease-in-out, -webkit-filter .2s ease-in-out;
    position: absolute;
    z-index: -99;

}

.Home-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: object-position .5s ease-in-out .3s, -webkit-filter .2s ease-out;
    transition: object-position .5s ease-in-out .3s, filter .2s ease-out;
    transition: object-position .5s ease-in-out .3s, filter .2s ease-out, -webkit-filter .2s ease-out;
    position: absolute;
    z-index: -99;

}

.Section-Active>.Home-image,
.Section-Active>.Home-video {
    object-position: center center;
    -webkit-filter: blur(0px) contrast(100%) brightness(1) !important;
            filter: blur(0px) contrast(100%) brightness(1) !important;
}

.Home-section>.Home-image {
    -webkit-filter: blur(10px) contrast(130%) brightness(1.2);
            filter: blur(10px) contrast(130%) brightness(1.2);

}

.Home-section>.Home-video {
    -webkit-filter: blur(10px) contrast(140%) brightness(1.7);
            filter: blur(10px) contrast(140%) brightness(1.7);

}

.Home-section:nth-child(odd)>.Home-image {
    object-position: right center;
}

.Home-section:nth-child(even)>.Home-image {
    object-position: left center;
}

.Home-section:nth-child(2)>.Home-image {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
}

.Home-section:nth-child(3)>.Home-image {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
}




.Home-text {
    display: flex;
    flex-flow: column;
    justify-content: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 100%;
    padding: 5px calc(2% + 3px);
    transition: all .3s ease-in-out, color .3s ease, opacity .3s ease-out .1s, width .2s ease-in-out, height .2s ease-out, -webkit-transform .3s ease-in-out;
    transition: all .3s ease-in-out, transform .3s ease-in-out, color .3s ease, opacity .3s ease-out .1s, width .2s ease-in-out, height .2s ease-out;
    transition: all .3s ease-in-out, transform .3s ease-in-out, color .3s ease, opacity .3s ease-out .1s, width .2s ease-in-out, height .2s ease-out, -webkit-transform .3s ease-in-out;
}

.Section-Active>.Home-text {
    opacity: 1 !important;
    -webkit-transform: none !important;
            transform: none !important;
    max-width: calc(500px + 20vw);
}

.Section-Active:first-child>.Home-text {
    max-width: 100% !important;
    border: none;
}

.Section-Active:last-child>.Home-text {
    max-width: calc(50% + 200px);
    padding-bottom: 10vh;
}

.Section-Active:nth-child(odd)>.Home-text {
    background-color: rgba(255, 255, 255, 0.7) !important;
    color: #0447ab !important;
    border-left: 1px double #1365df30;
}

.Section-Active:nth-child(even)>.Home-text {
    background-color: #1365df8f !important;
    color: white !important;
    border-right: 1px solid rgba(255, 255, 255, 0.2);
}


.Section-Active>.Home-loading{
    height: 100%;
}
.Home-section>.Home-loading{
    height: 100%;
    width: 100%;
}
.Home-loading {
    flex-grow: 1;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    color: #0447ab;
    font-size: calc(20px + .5vw + .3vh);
    font-weight: bold;
    background-color: #0447ab95;
    z-index: -100;

}

.Home-loading>h2 {
    height: 50%;
}

.Home-section:first-child {
    flex-flow: column;
    height: calc(94vh - 20px);
}

.Home-section:first-child>.Home-text {
    max-width: 100%;
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
    color: aliceblue;
    padding: 5px 10vw 5vh 10vw;
    align-items: flex-start;
    bottom: 0 !important;
    -webkit-transform: translateY(30vw);
            transform: translateY(30vw);
}

.Home-section:first-child>.Home-loading {
    width: 100%;
    z-index: -100;
}


.Home-section:nth-child(odd)>.Home-text {
    background-color: rgba(255, 255, 255);
    color: #1365df00;
    align-items: flex-end;
    -webkit-transform: translateX(-35vw);
            transform: translateX(-35vw);
    opacity: 0;
}


.Home-section:nth-child(even)>.Home-text {
    background-color: #1365df;
    color: rgba(255, 255, 255, 0);
    align-items: flex-start;
    -webkit-transform: translateX(35vw);
            transform: translateX(35vw);
    opacity: 0;
}

.Home-section:last-child>.Home-text {
    min-width: 50%;
    -webkit-transform: translateX(-10vw);
            transform: translateX(-10vw);
}


.Text-title {
    font-size: calc(20px + .7vw + .55vh);
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    line-height: calc(20px + .7vw + .55vh);
    margin: 2% 0;
    border-bottom: 3px double red;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;

}

.Text-paragraph-Wrapper {
    word-wrap: break-word;
}

.Text-paragraph {
    font-size: calc(8px + .6vw + .3vh);
    text-indent: 2em;
    text-align: justify;
    flex-grow: 1;
    margin-bottom: 2%;
}

.Para-in-link {
    text-decoration: underline;
    cursor: pointer;
    font-weight: 700;
}

.Para-ex-link {
    text-decoration: underline;
    cursor: pointer;
    font-weight: 700;
}


.Resolution-text {
    width: 50vw !important;
    max-width: none !important;
    top: 0;
    justify-content: flex-start;
    padding-top: 50px;
}

@media only screen and (max-width: 750px) {
    .Home-text {
        height: auto;
    }

    .Section-Active {
        overflow: hidden;
    }

    .Section-Active:nth-child(2) .Home-text {
        width: 75vw;
    }

    .Section-Active:nth-child(3) .Home-text {
        width: 75vw;
    }

    .Section-Active:nth-child(4) .Home-text {
        width: 75vw;

    }

    .Section-Active:nth-child(5) .Home-text {
        width: 100vw;
    }

    .Section-Active:nth-child(1)>.Home-image {
        object-position: left center;
        -webkit-animation: backgroundCenterMoveToLeft 23s ease-in infinite;
                animation: backgroundCenterMoveToLeft 23s ease-in infinite;
    }

    .Section-Active:nth-child(2)>.Home-image {
        object-position: left center;
        -webkit-animation: backgroundZoomToRight 35s ease-in-out infinite;
                animation: backgroundZoomToRight 35s ease-in-out infinite;
    }

    .Section-Active:nth-child(3)>.Home-image {
        object-position: left center;
        -webkit-animation: backgroundCenterMoveHalfToRight 20s ease-in-out infinite;
                animation: backgroundCenterMoveHalfToRight 20s ease-in-out infinite;
    }

    .Section-Active:nth-child(4)>.Home-image {
        object-position: left bottom;
        -webkit-animation: backgroundBotMoveToRight 20s ease-in-out infinite, rotate20 20s ease-in-out infinite;
                animation: backgroundBotMoveToRight 20s ease-in-out infinite, rotate20 20s ease-in-out infinite;
    }

    .Section-Active:nth-child(5)>.Home-image {
        object-position: left center;
        -webkit-animation: backgroundCenterMoveToRight 20s ease-in-out infinite;
                animation: backgroundCenterMoveToRight 20s ease-in-out infinite;
    }

    .Resolution-text {
        top: calc(50% - 300px);
        width: 100vw !important;
        max-width: none !important;
        height: auto;
        transition: all .3s ease-in-out;
        padding-top: 0;
    }

    .Home-section:nth-child(even) {
        flex-flow: row !important;
    }

    .Home-loading {
        height: 100%;
        width: 100%;
        position: absolute;
        background-color: #0447ab95;
        color: white;
        z-index: 50;
    }

}

@media only screen and (max-width: 950px) and (min-width: 751px) {
    .Section-Active>.Home-text {
        max-width: calc(100vw - 240px);
        height: auto;
    }

    .Section-Active:last-child>.Home-text {
        max-width: calc(100vw - 240px);
    }

    .Section-Active:nth-child(1)>.Home-image {
        object-position: left center;
        -webkit-animation: backgroundCenterMoveToLeft 23s ease-in infinite;
                animation: backgroundCenterMoveToLeft 23s ease-in infinite;
    }

    .Section-Active:nth-child(2)>.Home-image {
        object-position: left center;
        -webkit-animation: backgroundZoomToRight 35s ease-in-out infinite;
                animation: backgroundZoomToRight 35s ease-in-out infinite;
    }

    .Section-Active:nth-child(3)>.Home-image {
        object-position: left center;
        -webkit-animation: backgroundCenterMoveHalfToRight 20s ease-in-out infinite;
                animation: backgroundCenterMoveHalfToRight 20s ease-in-out infinite;
    }

    .Section-Active:nth-child(4)>.Home-image {
        object-position: left bottom;
        -webkit-animation: backgroundBotMoveToRight 20s ease-in-out infinite, rotate20 20s ease-in-out infinite;
                animation: backgroundBotMoveToRight 20s ease-in-out infinite, rotate20 20s ease-in-out infinite;
    }

    .Section-Active:nth-child(5)>.Home-image {
        object-position: left center;
        -webkit-animation: backgroundCenterMoveToRight 20s ease-in-out infinite;
                animation: backgroundCenterMoveToRight 20s ease-in-out infinite;
    }

    .Resolution-text {
        width: calc(100vw - 120px) !important;
        max-width: none !important;
        height: auto;
        transition: all .3s ease-in-out;
        top: calc(50% - 300px);
        padding-top: 0;
    }

    .Home-section:nth-child(even) {
        flex-flow: row !important;
    }

    .Home-loading {
        height: 100%;
        width: 100%;
        position: absolute;
        background-color: #0447ab95;
        color: white;
        z-index: 50;
    }
}



@-webkit-keyframes backgroundZoomToRight {
    0% {
        object-position: left center;
        -webkit-transform: scale(1) rotateY(180deg);
                transform: scale(1) rotateY(180deg);
        width: 100vw;

    }

    50% {
        object-position: right center;
        -webkit-transform: scale(2) rotateY(180deg);
                transform: scale(2) rotateY(180deg);
        width: 200vw;

    }

    100% {
        object-position: left center;
        -webkit-transform: scale(1) rotateY(180deg);
                transform: scale(1) rotateY(180deg);
        width: 100vw;

    }
}



@keyframes backgroundZoomToRight {
    0% {
        object-position: left center;
        -webkit-transform: scale(1) rotateY(180deg);
                transform: scale(1) rotateY(180deg);
        width: 100vw;

    }

    50% {
        object-position: right center;
        -webkit-transform: scale(2) rotateY(180deg);
                transform: scale(2) rotateY(180deg);
        width: 200vw;

    }

    100% {
        object-position: left center;
        -webkit-transform: scale(1) rotateY(180deg);
                transform: scale(1) rotateY(180deg);
        width: 100vw;

    }
}

@-webkit-keyframes backgroundCenterMoveToLeft {
    0% {
        object-position: right center;
    }

    50% {
        object-position: left center;
    }

    100% {
        object-position: right center;
    }
}

@keyframes backgroundCenterMoveToLeft {
    0% {
        object-position: right center;
    }

    50% {
        object-position: left center;
    }

    100% {
        object-position: right center;
    }
}

@-webkit-keyframes backgroundCenterMoveHalfToRight {
    0% {
        object-position: 0% center;
    }

    50% {
        object-position: 50% center;
    }

    100% {
        object-position: 0% center;
    }
}

@keyframes backgroundCenterMoveHalfToRight {
    0% {
        object-position: 0% center;
    }

    50% {
        object-position: 50% center;
    }

    100% {
        object-position: 0% center;
    }
}

@-webkit-keyframes backgroundCenterMoveToRight {
    0% {
        object-position: left center;
    }

    50% {
        object-position: right center;
    }

    100% {
        object-position: left center;
    }
}

@keyframes backgroundCenterMoveToRight {
    0% {
        object-position: left center;
    }

    50% {
        object-position: right center;
    }

    100% {
        object-position: left center;
    }
}

@-webkit-keyframes backgroundBotMoveToRight {
    0% {
        object-position: left bottom;
    }

    50% {
        object-position: right bottom;
    }

    100% {
        object-position: left bottom;
    }
}

@keyframes backgroundBotMoveToRight {
    0% {
        object-position: left bottom;
    }

    50% {
        object-position: right bottom;
    }

    100% {
        object-position: left bottom;
    }
}

@-webkit-keyframes rotate20 {
    0% {
        -webkit-transform: rotate(0deg) scale(150%);
                transform: rotate(0deg) scale(150%);
    }

    50% {
        -webkit-transform: rotate(20deg) scale(150%);
                transform: rotate(20deg) scale(150%);
    }

    100% {
        -webkit-transform: rotate(0deg) scale(150%);
                transform: rotate(0deg) scale(150%);
    }
}

@keyframes rotate20 {
    0% {
        -webkit-transform: rotate(0deg) scale(150%);
                transform: rotate(0deg) scale(150%);
    }

    50% {
        -webkit-transform: rotate(20deg) scale(150%);
                transform: rotate(20deg) scale(150%);
    }

    100% {
        -webkit-transform: rotate(0deg) scale(150%);
                transform: rotate(0deg) scale(150%);
    }
}
/* CAN NOT USE IN SAFARI MOBILE */
/* transform-style */
/* perspective */
/* backface-visibility */

.Product-container {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    transition: -webkit-transform .3s ease-in-out;
    transition: transform .3s ease-in-out;
    transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out;
}

.Product-type-Screen {
    max-height: 80vh;
    height: 700px;
    width: 100vw;
    overflow: hidden;
    display: flex;
    justify-content: center;
    transition: box-shadow .5s ease-in-out .5s;
    padding-top: calc(4vh + 20px);
}

.Product-type-Active {
    box-shadow: rgb(44, 62, 80) 0px 15px 30px -20px;

}

.Product-type-Active>.Product-item-Wrapper {
    padding-bottom: 5%;
}

.Product-item-Wrapper {
    width: 0px;
    height: 100%;
    overflow-x: visible;
    align-items: flex-start;
    display: flex;
    justify-content: center;
    position: relative;
    padding-bottom: 5%;
    transition: padding .5s ease-in-out;

}

.Product-item-Slide {
    overflow-x: visible;
    transition: -webkit-transform .2s ease-in-out;
    transition: transform .2s ease-in-out;
    transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.Product-item-Container {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    transition: background-color .3s ease-in, -webkit-transform .3s ease-in-out .2s;
    transition: transform .3s ease-in-out .2s, background-color .3s ease-in;
    transition: transform .3s ease-in-out .2s, background-color .3s ease-in, -webkit-transform .3s ease-in-out .2s;

}

.Product-item-Container-Scroll {
    transition: background-color .4s ease-out;
}

.Product-item-Cover {
    padding: 20px;

}

.Product-item-Cover_Non-safari {
    -webkit-perspective: 2000px;
            perspective: 2000px;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
}


.Product-item {
    max-height: calc(80vh - 200px);
    height: 450px;
    width: 500px;
    max-width: 100vw;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    transition: -webkit-transform .35s ease-out;
    transition: transform .35s ease-out;
    transition: transform .35s ease-out, -webkit-transform .35s ease-out;
    position: relative;
    cursor: pointer;
}

.Product-item_Non-safari {
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
}

.Product-item-Cover-Hover>.Product-item {
    -webkit-transform: rotateX(-180deg);
            transform: rotateX(-180deg);
    box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 3px, rgba(0, 0, 0, 0.24) 0px 4px 4px;
}


.Item-image {
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    height: 100%;
    width: 500px;
    max-width: 100%;
    object-fit: contain;
    position: absolute;
    transition: box-shadow .3s ease-in-out .3s, width .2s ease-in-out, height .2s ease-in-out, background-color .4s ease-out, -webkit-transform .4s ease-in-out .1s;
    transition: box-shadow .3s ease-in-out .3s, transform .4s ease-in-out .1s, width .2s ease-in-out, height .2s ease-in-out, background-color .4s ease-out;
    transition: box-shadow .3s ease-in-out .3s, transform .4s ease-in-out .1s, width .2s ease-in-out, height .2s ease-in-out, background-color .4s ease-out, -webkit-transform .4s ease-in-out .1s;
}
.Item-loading {
    color: rgb(0, 33, 105);
    font-size: calc(12px + .6vw + .3vh);
    font-weight: bolder;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
}
.Item-loading h2 {
    height: 50%;
}
.Product-item-Cover-Hover .Item-image {
    -webkit-transform: rotateX(-180deg) rotateY(-360deg);
            transform: rotateX(-180deg) rotateY(-360deg);
    width: 200px;
    max-width: 50%;
    height: 144px;
    bottom: 60px;
    left: 0;
    box-shadow: none;
    z-index: 91;
    transition: box-shadow .1s, width .4s ease-out .1s, height .4s ease-out .1s, -webkit-transform .3s ease-in-out .2s;
    transition: box-shadow .1s, transform .3s ease-in-out .2s, width .4s ease-out .1s, height .4s ease-out .1s;
    transition: box-shadow .1s, transform .3s ease-in-out .2s, width .4s ease-out .1s, height .4s ease-out .1s, -webkit-transform .3s ease-in-out .2s;
}


.Item-name {
    width: 100%;
    height: 100%;
    position: absolute;
    -webkit-transform: rotateX(180deg);
            transform: rotateX(180deg);
    padding: 10px 20px;
    bottom: 0%;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    transition: all .3s ease-in-out .3s, color .3s ease-out .3s, box-shadow .3s ease-out .3s;
    color: rgb(255, 255, 255);
}

.Item-detail {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 60px;
    padding: 0 15px;
    color: rgb(0, 4, 46);
    -webkit-transform: rotateX(180deg) scale(.3);
            transform: rotateX(180deg) scale(.3);

    transition: color .2s ease-in-out, opacity .1s ease-in .1s, -webkit-transform .2s ease-in;

    transition: color .2s ease-in-out, opacity .1s ease-in .1s, transform .2s ease-in;

    transition: color .2s ease-in-out, opacity .1s ease-in .1s, transform .2s ease-in, -webkit-transform .2s ease-in;
}


.Product-item-Cover-Hover .Item-detail {
    color: white;
    -webkit-transform: rotateX(180deg) scale(1);
            transform: rotateX(180deg) scale(1);
    transition: color .3s ease-in-out .1s, opacity .2s ease-out, -webkit-transform .4s ease-out .1s;
    transition: color .3s ease-in-out .1s, opacity .2s ease-out, transform .4s ease-out .1s;
    transition: color .3s ease-in-out .1s, opacity .2s ease-out, transform .4s ease-out .1s, -webkit-transform .4s ease-out .1s;
}

.Item-detail-Mask {
    position: relative;
    float: left;
    clear: none;
    left: 0;
    top: 0;
    background-color: transparent;
    width: 200px;
    height: 144px;
    margin-bottom: 5px;
}

.Item-detail-Paragraph {
    text-align: justify;
    margin-bottom: calc(5px + 1vmin);
    font-size: calc(7px + 1.5vmin);
    text-indent: 10%;
}

.Product-item-Cover-Hover .Item-name {
    color: rgb(0, 33, 105);
    background-color: #47b5ff9b;
    z-index: -1;

}

.Item-guide {
    right: 0;
    position: absolute;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    height: 20%;
    width: 100%;
    padding-bottom: 2%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: underline;
    z-index: 90;
    color: rgb(7, 63, 185);
    text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.8);
    background-image: linear-gradient(to top, #0d3755d3, #47b5ff00);
    -webkit-backdrop-filter: blur(1.5px);
            backdrop-filter: blur(1.5px);
    font-size: calc(10px + 1.5vmin);
    outline: none;
    bottom: 0;
}

.Item-guide_Non-safari {
    top: 0;
    -webkit-transform: rotateX(180deg);
            transform: rotateX(180deg);
}


.Product-item h1 {
    transition: font-size .1s ease-in-out .4s, -webkit-transform .2s ease-in-out .3s;
    transition: transform .2s ease-in-out .3s, font-size .1s ease-in-out .4s;
    transition: transform .2s ease-in-out .3s, font-size .1s ease-in-out .4s, -webkit-transform .2s ease-in-out .3s;
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
    font-size: 0px;
}

.Product-item-Cover-Hover h1 {
    -webkit-transform: none;
            transform: none;
    font-size: calc(10px + 1vw + .7vh);
}

.Product-button {
    position: absolute;
    height: calc(40px + 8.5vw + 6vh);
    width: calc(60px + 8.5vw + 6vh);
    border-radius: 50%;
    overflow: visible;
    display: flex;
    color: rgb(6, 50, 104);
    align-items: flex-start;
    padding-top: calc(10px + 2vw + 1vh);
    z-index: 5;
    bottom: calc(0% - 5vw - 3.5vh);
    transition: box-shadow .4s ease-in, background-color .4s ease-out .1s, color .3s ease-out .1s;
    cursor: pointer;
    font-size: calc(20px + 1vw + 1vh);
    background-color: #47b5ff9b;
    box-shadow: none;
    transition: box-shadow .5s ease-out .2s, color .3s ease-in-out .2s, -webkit-transform .5s ease-in .1s;
    transition: transform .5s ease-in .1s, box-shadow .5s ease-out .2s, color .3s ease-in-out .2s;
    transition: transform .5s ease-in .1s, box-shadow .5s ease-out .2s, color .3s ease-in-out .2s, -webkit-transform .5s ease-in .1s;
}

/* box-shadow: #47B5FF 0px 0px 15px 6px, #47b5ffbf 0px 0px 40px 6px inset, white 0px 0px 5px 1px, rgba(255, 255, 255, 0.679) 0px 0px 30px 5px inset; */


.Product-button:hover {
    background-color: #47B5FF;
    box-shadow: rgba(0, 107, 178, 0.5) 0px 0px 10px 1px, rgb(230, 242, 253) 0px 0px 30px 5px inset, rgba(255, 255, 255, 0.7) 0px 0px 70px 20px inset, #47b5ffd9 0px 0px 50px 5px, #0199fe 0px 0px 10px 5px inset;
    color: rgba(255, 255, 255, 0.9);
}

.Product-button:active {
    background-color: #47B5FF;
    box-shadow: rgb(240, 249, 255) 0px 0px 10px 2px, rgb(230, 242, 253) 0px 0px 7px 3px inset, rgba(255, 255, 255, 0.7) 0px 0px 50px 10px inset, rgba(99, 164, 255) 0px 0px 50px 10px, rgb(118, 221, 255) 0px 0px 10px 5px inset;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    transition: all .1s ease-out, color .2s ease-out;
    color: #1363DF;
}



.Left-button {
    left: calc(-3.5vh - 55vw);
    padding-right: calc(15px + 2.5vw + 1.5vh);
    justify-content: right;
    -webkit-transform: translateX(-10vw);
            transform: translateX(-10vw);
}

.Right-button {
    right: calc(-3.5vh - 55vw);
    padding-left: calc(15px + 2.5vw + 1.5vh);
    justify-content: left;
    -webkit-transform: translateX(10vw);
            transform: translateX(10vw);
}

.Product-type-Active .Product-button {
    -webkit-transform: none;
            transform: none;
}

.Product-dot-slider {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: calc(83vw - 12vh - 120px);
    height: calc(3.5vw + 2.5vh + 40px);
    position: absolute;
    bottom: 0;
    z-index: 15;
}

.Product-dot-slider>.Dot-slider {
    height: 15px;
    width: 15px;
    border: 2px solid #1363DF;
    border-radius: 50%;
    cursor: pointer;
    transition: border .5s ease-in-out, background-color .3s ease-out .2s, opacity .3s ease-in-out .2s;
    margin: 5% 2%;
    opacity: 1;
}

.Product-dot-slider>.Dot-slider-Active {
    border: 3px solid #47B5FF;
    background-color: #47B5FF;
}

.Product-Active {
    height: 100%;
    width: 100%;
    padding: 5vh 5vw;
    display: flex;
    flex-flow: row;
    background-color: #47b5ff9b;
    -webkit-animation: growUp .3s ease-in-out;
            animation: growUp .3s ease-in-out;
    cursor: pointer;
    position: relative;
}

@-webkit-keyframes growUp {
    from {
        -webkit-transform: scale(.6) translateY(-100%);
                transform: scale(.6) translateY(-100%);
        opacity: 0;
    }

    to {
        -webkit-transform: scale(1) translateY(0);
                transform: scale(1) translateY(0);
        opacity: 1;
    }
}

@keyframes growUp {
    from {
        -webkit-transform: scale(.6) translateY(-100%);
                transform: scale(.6) translateY(-100%);
        opacity: 0;
    }

    to {
        -webkit-transform: scale(1) translateY(0);
                transform: scale(1) translateY(0);
        opacity: 1;
    }
}

.Product-Active-image {
    max-height: 100%;
    height: 500px;
    width: 500px;
    max-width: 30%;
    object-fit: scale-down;
}

.Product-Active-text {
    flex-grow: 1;
    padding: 0px 20px;

}

.Product-Active-name {
    color: rgb(0, 33, 105);
    margin-bottom: 20px;
    text-indent: 10%;
    width: 100%;
    text-align: left;
    font-size: calc(12px + .6vh + .7vw);
    text-indent: 10%;
}

.Product-Active-detail {}

.Product-Active-detail-Mask {}

.Product-Active-detail-Paragraph {
    color: white;
    text-align: justify;
    margin-bottom: 20px;
    font-size: calc(10px + .5vh + .7vw);
    text-indent: 10%;
}

.Product-Active-guide {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: underline;
    font-size: calc(12px + 1.5vmin);
    font-weight: 500;
    position: absolute;
    bottom: 0;
    padding-bottom: 2%;
}

@media only screen and (max-width: 480px) {
    .Product-item-Cover-Hover h1 {
        -webkit-transform: none;
                transform: none;
        font-size: calc(20px + .7vw + .5vh);
    }

    .Product-button {
        height: calc(20px + 8vw + 6vh);
        width: calc(30px + 8vw + 6vh);
        align-items: center;
        padding-top: 0;
        bottom: 30%;
        font-size: 30px;
        background-color: #47b5ff56;
        color: white;
    }

    .Product-button:hover {
        background-color: #47b5ff56 !important;
        box-shadow: 0px 0px 0px transparent;
    }

    .Product-button:active {
        box-shadow: rgba(0, 107, 178, 0.5) 0px 0px 10px 1px, rgb(230, 242, 253) 0px 0px 30px 5px inset, rgba(255, 255, 255, 0.7) 0px 0px 70px 20px inset, #47b5ffd9 0px 0px 50px 5px, #0199fe 0px 0px 10px 5px inset;
    }

    .Left-button {
        left: calc(-54vw - 15px - 3vh);
        padding-right: 15px;
        justify-content: right;

    }

    .Right-button {
        right: calc(-54vw - 15px - 3vh);
        padding-left: 15px;
        justify-content: left;

    }

    .Product-dot-slider {
        width: 100vw;
        height: 20%
    }

    .Product-dot-slider>.Dot-slider {
        height: 10px;
        width: 10px;
        border: 1px solid #1363DF;
        margin: 5px;

    }

    .Product-dot-slider>.Dot-slider-Active {
        border: 1px solid #47B5FF;
    }

    .Product-Active {
        padding: 2vh 1vw;
        flex-flow: column;
        background-color: #47b5ff9b;
        -webkit-animation: growUp .4s ease-in-out;
                animation: growUp .4s ease-in-out;
        cursor: pointer;
    }

    .Product-Active-image {
        max-height: 20%;
        max-width: 100%;
    }

    .Product-Active-text {
        flex-grow: 1;
        padding: 0px 20px;

    }

    .Product-Active-name {
        color: rgb(0, 33, 105);
        margin-bottom: 20px;
        text-indent: 10%;
        width: 100%;
        text-align: left;
        font-size: calc(10px + .6vh + .7vw);
        text-indent: 10%;


    }

    .Product-Active-detail-Paragraph {
        color: white;
        text-align: justify;
        margin-bottom: 20px;
        font-size: calc(10px + .5vh + .7vw);
        text-indent: 10%;
    }
}

@media only screen and (max-width: 780px) and (min-width: 481px) {
    .Product-item-Cover {
        /* pointer-events: none; */
    }

    .Product-button {
        height: calc(30px + 8vw + 6vh);
        width: calc(40px + 8vw + 6vh);
        align-items: center;
        padding-top: 0;
        bottom: 40%;
        font-size: 40px;
        background-color: #47b5ff56;
        color: white;
    }

    .Product-button:hover {
        background-color: #47b5ff56 !important;
        box-shadow: 0px 0px 0px transparent;
    }

    .Product-button:active {
        box-shadow: rgba(0, 107, 178, 0.5) 0px 0px 10px 1px, rgb(230, 242, 253) 0px 0px 30px 5px inset, rgba(255, 255, 255, 0.7) 0px 0px 70px 20px inset, #47b5ffd9 0px 0px 50px 5px, #0199fe 0px 0px 10px 5px inset;
    }

    .Product-button:active {
        background-color: none !important;
        box-shadow: none !important;
        -webkit-transform: scale(1) !important;
                transform: scale(1) !important;
    }

    .Left-button {
        left: -60vw;
        padding-right: 30px;
        justify-content: right;

    }

    .Right-button {
        right: -60vw;
        padding-left: 30px;
        justify-content: left;

    }

    .Product-dot-slider {
        width: 100vw;
        height: 20%;
    }

    .Product-dot-slider>.Dot-slider {
        height: 10px;
        width: 10px;
        border: 2px solid #1363DF;
        margin: 10px;

    }

    .Product-dot-slider>.Dot-slider-Active {
        border: 2px solid #47B5FF;
    }
}
.App-contact>img {
    height: 100%;
    width: 100vw;
    position: absolute;
    z-index: 0;
    object-fit: cover;
}

.Contact-background {
    position: fixed;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
}

.Contact-form-container {
    height: calc(100% - 6vh - 20px);
    width: 100vw;
    box-shadow: rgb(1 17 36) 0px 5px 12px 1px;
    z-index: 1;
    display: flex;
    justify-content: center;
    background-color: #47B5FF2b;
}

.Contact-form-model {
    height: 100%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(255, 255, 255, .1), rgba(0, 0, 0, 0));
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    color: white;
    padding: 0 5%;
    display: flex;
    flex-flow: column;
}

.Contact-form-model>p {
    font-size: calc(12px + .5vw + .3vh);
    padding: 10px;
    background-color: #47B5FF2b;
}

.Contact-form-model>p>span {
    font-weight: bold;
    font-style: italic;
}

.Form-outer {
    flex-grow: 1;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    flex-flow: column;
    overflow-y: scroll;
    overflow-x: hidden;
    mix-blend-mode: color-dodge;
    background: linear-gradient(to left, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1));
    -webkit-backdrop-filter: blur(80px);
            backdrop-filter: blur(80px);
    padding-bottom: 40px;

}

.Dodge-footer {
    padding-bottom: calc(200px);
}

.Form-outer::-webkit-scrollbar {
    width: 0px;
}



.Form-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: calc(100vw - 240px);
    padding: 0 120px;
}

.Submit-button {
    width: 100%;
    display: flex;
    justify-content: right;
    margin-top: 50px;

}

.Submit-button-inactive {
    width: 100%;
    display: flex;
    justify-content: right;
    margin-top: 50px;

}

.Submit-button button {
    outline: none;
    width: 200px;
    height: 80px;
    color: white;
    border: 1px solid transparent;
    border-radius: 15px;
    font-size: calc(12px + .6vw + .3vh);
    background-color: #1363DF;
    cursor: pointer;
    box-shadow: 0px -3px 5px 1px #ffffffa5;
    transition: all 1s;
    margin-right: 60px;
}

.Submit-button-inactive button {
    outline: none;
    width: 200px;
    height: 80px;
    color: white;
    border: 1px solid transparent;
    border-radius: 15px;
    font-size: calc(12px + .6vw + .3vh);
    background-color: #303f55;
    box-shadow: 0px -3px 5px 1px #ffffffa5;
    transition: all 1s;
    margin-right: 60px;

}

.Submit-button button:hover {
    box-shadow: 0px -5px 20px 1px #0dc3f0 inset, 0px 5px 10px 1px #ffffff, 0px 0px 20px 5px rgba(255, 255, 255, 0.4) inset;
}

.Form-side {
    max-width: calc(50% - 20px);
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.Form-side:first-child {
    width: 45%;
}

.Form-footer {
    width: 100%;
    display: flex;
}

.Form-Element {
    border: none;
    padding: 20px 30px;
    box-shadow: 0px -5px 20px 1px #0dc3f0 inset, 0px 5px 10px 1px #ffffff, 0px 0px 20px 5px rgba(255, 255, 255, 0.4) inset;
    border-radius: 50px;
    margin-top: 65px;

}

.Form-Element>legend {
    font-weight: bolder;
    border-left: 5px solid #1363DF;
    border-bottom: none;
    padding: 15px 30px 15px 15px;
    -webkit-transform: translate(0%, -50%);
            transform: translate(0%, -50%);
    text-align: left;
    margin-left: 15px;
    border-radius: 10px 60px 0px 0;
    background: linear-gradient(to top, rgba(4, 34, 100, 0.1), #ffffff70);
    color: #1363DF;
    -webkit-text-stroke-width: .5px;
    box-shadow: 5px 5px 20px 0px #0dc3f0 inset, -5px 5px 20px 0px #0dc3f0 inset, 0px -5px 15px 3px #ffffff;
    max-width: calc(100% - 30px);
    -webkit-animation: changeBoxShadow 6s ease-in-out 0s infinite;
            animation: changeBoxShadow 6s ease-in-out 0s infinite;
}

.Form-Element>legend>div {
    /* background: linear-gradient(to left, #1363DF, white); */
    -webkit-animation: changeBackgroundGradient 6s ease 0s infinite;
            animation: changeBackgroundGradient 6s ease 0s infinite;

}

.Form-Element>legend>div {
    font-size: calc(10px + .6vw + .3vh);
    -webkit-background-clip: text;
            background-clip: text;
    -webkit-text-fill-color: transparent;
}

@-webkit-keyframes changeBackgroundGradient {
    0% {
        background: linear-gradient(to left, #3292ff, white);
        -webkit-background-clip: text;
                background-clip: text;


    }

    25% {
        background: white;
        -webkit-background-clip: text;
                background-clip: text;
        color: #ffffff;

    }

    50% {
        background: linear-gradient(to right, #3292ff, white);
        -webkit-background-clip: text;
                background-clip: text;


    }

    75% {
        background: white;
        -webkit-background-clip: text;
                background-clip: text;
        color: #ffffff;

    }

    100% {
        background: linear-gradient(to left, #3292ff, white);
        -webkit-background-clip: text;
                background-clip: text;


    }
}

@keyframes changeBackgroundGradient {
    0% {
        background: linear-gradient(to left, #3292ff, white);
        -webkit-background-clip: text;
                background-clip: text;


    }

    25% {
        background: white;
        -webkit-background-clip: text;
                background-clip: text;
        color: #ffffff;

    }

    50% {
        background: linear-gradient(to right, #3292ff, white);
        -webkit-background-clip: text;
                background-clip: text;


    }

    75% {
        background: white;
        -webkit-background-clip: text;
                background-clip: text;
        color: #ffffff;

    }

    100% {
        background: linear-gradient(to left, #3292ff, white);
        -webkit-background-clip: text;
                background-clip: text;


    }
}

@-webkit-keyframes changeBoxShadow {
    0% {
        box-shadow: 5px 5px 20px 1px #0dc3f0 inset, -5px 5px 20px 1px #0dc3f0 inset, 0px -5px 15px 3px #ffffff;
    }

    25% {
        box-shadow: -5px 5px 20px 1px #0dc3f0 inset, -5px -5px 20px 1px #0dc3f0 inset, 5px 0px 10px 1px #ffffff;
    }

    50% {
        box-shadow: 5px -5px 20px 1px #0dc3f0 inset, -5px -5px 20px 1px #0dc3f0 inset, 0px 5px 15px 3px #ffffff;
    }

    75% {
        box-shadow: 5px 5px 20px 1px #0dc3f0 inset, 5px -5px 20px 1px #0dc3f0 inset, -5px 0px 10px 1px #ffffff;
    }

    100% {
        box-shadow: 5px 5px 20px 1px #0dc3f0 inset, -5px 5px 20px 1px #0dc3f0 inset, 0px -5px 15px 3px #ffffff;
    }
}

@keyframes changeBoxShadow {
    0% {
        box-shadow: 5px 5px 20px 1px #0dc3f0 inset, -5px 5px 20px 1px #0dc3f0 inset, 0px -5px 15px 3px #ffffff;
    }

    25% {
        box-shadow: -5px 5px 20px 1px #0dc3f0 inset, -5px -5px 20px 1px #0dc3f0 inset, 5px 0px 10px 1px #ffffff;
    }

    50% {
        box-shadow: 5px -5px 20px 1px #0dc3f0 inset, -5px -5px 20px 1px #0dc3f0 inset, 0px 5px 15px 3px #ffffff;
    }

    75% {
        box-shadow: 5px 5px 20px 1px #0dc3f0 inset, 5px -5px 20px 1px #0dc3f0 inset, -5px 0px 10px 1px #ffffff;
    }

    100% {
        box-shadow: 5px 5px 20px 1px #0dc3f0 inset, -5px 5px 20px 1px #0dc3f0 inset, 0px -5px 15px 3px #ffffff;
    }
}

.Form-input-wrapper {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    margin-top: 30px;
    -webkit-transform: translateY(-40px);
            transform: translateY(-40px);
    font-size: calc(6px + .3vw + .2vh);

}

.Form-input-wrapper>div {
    display: flex;
    width: 100%;

}

.Form-side input {
    outline: none;
    flex-grow: 1;
    color: white;
    background: linear-gradient(to top, #47b5ffa6, rgba(0, 0, 0, 0));
    border: none;
    border-bottom: 1px solid white;
    padding: 5px 20px 5px 5px;
    border-bottom-right-radius: 20px;
    width: 50px;
    font-size: calc(8px + .6vw + .3vh);

}

.Form-side select {
    font-size: calc(8px + .6vw + .3vh);
    outline: none;
    color: white;
    background: linear-gradient(to top, #47b5ffa6, rgba(0, 0, 0, 0));
    border: none;
    border-bottom: 1px solid white;
    padding: 5px 0px 5px 10px;
    border-bottom-left-radius: 20px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;

}

.Form-side option {
    color: blue;
    background-image: linear-gradient(to top, #47b5ffa6, rgba(0, 0, 0, 0));
    font-size: calc(8px + .6vw + .3vh);
}


.Form-input-wrapper h3 {
    text-align: left;
    margin-bottom: 10px;
}

.Form-input-wrapper h3>span {
    background-color: white;
    color: #06283D;
    padding: 5px 10px;
}

.Form-input-wrapper_email input {
    border-bottom-left-radius: 20px;
    padding: 5px 15px;

}

.Form-input-wrapper_address input {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 20px;
    padding: 5px 5px 5px 15px;



}

.Form-input-wrapper_address select {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 20px;
    padding: 5px;

}



.Form-checkbox {
    display: flex;
    flex-flow: row wrap;
}

.Form-checkbox>div {
    flex-flow: row wrap;
    font-size: calc(6px + .5vw + .2vh);

}

.Form-checkbox>div>div {
    cursor: pointer;
    padding: 10px 15px;
    margin: 0px 7.5px;
    margin: 10px;
    border: 2px solid white;
    border-radius: 15px;
    background-image: linear-gradient(to top, #47b5ffa6, rgba(0, 0, 0, 0));
    transition: background-color .3s ease, border .5s ease-in-out, box-shadow .5s ease-out;
}

.Form-checkbox>div>div>label {
    cursor: pointer;
}

.Form-checkbox .Checked-box {
    background-image: none;
    background-color: #1363DF;
    border: 2px solid #0dc3f0;
    border-bottom: 2px solid #1363DF;
    box-shadow: 0px 5px 20px 1px #0dc3f0 inset, 0px 0px 15px 5px #d1f1ff;

}
.Checked-box>input {
    display: none;
}

.Form-checkbox input {
    width: 0;
}

.Form-input-wrapper h4 {
    color: rgb(209, 10, 0);
    text-shadow: 0px 0px 30px white;
    font-size: calc(10px + .4vw + .2vh);
    -webkit-text-stroke: rgb(255, 81, 81) .5px;
    margin: 10px 15px;
    padding: 5px 10px;
    background: linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.2));
    box-shadow: 0px -4px 7px 1px black inset;
    font-weight: 400;
}



@media screen and (max-width: 450px) {

    .Contact-form-model {
        background-color: #06283d5b;
    }

    .Form-outer {
        width: 100vw;
        max-width: 100vw;
        padding: 50px 0px 70px 0px;

    }

    .Dodge-footer {
        padding-bottom: calc(30vh + 100px) !important;
    }

    .Form-container {
        flex-flow: column;
        width: 100vw;
        padding: 0px;
    }

    .Form-side {
        max-width: 100% !important;
        width: 100% !important;
    }

    .Form-Element {
        border: none;
        padding: 20px 30px;
        box-shadow: none;
        border-radius: 0px;
        margin-top: 0px;
    }

    .Form-Element:last-child {
        padding: 30px 20px 0 30px
    }

    .Form-footer {
        flex-flow: column;
    }

    .Form-checkbox>div>div {
        cursor: pointer;
        padding: 5px 15px 10px 15px;

    }

    .Submit-button {
        margin-top: 0px;
    }

    .Submit-button-inactive {
        margin-top: 0px;
    }

    .Submit-button button {
        width: 120px;
        height: 60px;
        margin-right: 10%;
    }

    .Submit-button-inactive button {
        width: 120px;
        height: 60px;
        margin-right: 10%;
    }
}

@media only screen and (min-width: 450px) and (max-width: 780px) {

    .Contact-form-model {
        background-color: #06283d5b;
    }

    .Form-outer {
        width: 100vw;
        max-width: 100vw;
        padding: 50px 10px 50px 10px;

    }

    .Dodge-footer {
        padding-bottom: calc(30vh) !important;
    }

    .Form-container {
        flex-flow: column;
        width: 100vw;
        padding: 0px;
    }

    .Form-side {
        max-width: 100% !important;
        width: 100% !important;
    }

    .Form-Element {
        border: none;
        padding: 20px 50px;
        box-shadow: none;
        border-radius: 0px;
        margin-top: 0px;
    }

    .Form-Element:last-child {
        padding-bottom: 0px;
    }

    .Form-footer {
        flex-flow: column;
    }

    .Form-checkbox>div>div {
        cursor: pointer;
        padding: 5px 15px 10px 15px;

    }

    .Submit-button {
        margin-top: 0px;
    }

    .Submit-button-inactive {
        margin-top: 0px;
    }

    .Submit-button button {
        width: 140px;
        height: 70px;
        margin-right: 15%;
    }

    .Submit-button-inactive button {
        width: 140px;
        height: 70px;
        margin-right: 15%;
    }
}

@media screen and (min-width: 780px) and (max-width: 980px) {
    .Contact-form-model {
        background-color: #06283d5b;
    }

    .Form-outer {
        width: 100vw;
        max-width: 100vw;
        padding: 50px 10px;
    }

    .Dodge-footer {
        padding-bottom: calc(20vh + 50px) !important;
    }

    .Form-container {
        flex-flow: column;
        width: 100vw;
        padding: 0px 5%;
    }

    .Form-side {
        max-width: 100% !important;
        width: 100% !important;
    }

    .Form-Element {
        border: none;
        padding: 20px 50px;
        box-shadow: none;
        border-radius: 0px;
        margin-top: 0px;
    }

    .Form-Element:last-child {
        padding-bottom: 0px;
    }

    .Form-footer {
        flex-flow: column;
    }

    .Submit-button {
        margin-top: 0px;
    }

    .Submit-button-inactive {
        margin-top: 0px;
    }

    .Submit-button button {
        width: 160px;
        height: 80px;
        margin-right: 15%;
    }

    .Submit-button-inactive button {
        width: 160px;
        height: 80px;
        margin-right: 15%;
    }


}

@media screen and (min-width: 980px) and (max-width: 1200px) {
    .Form-container {
        width: 100vw;
        padding: 0 5%;
    }
}
.Footer-wheel {
    position: absolute;
    display: flex;
    flex-flow: column;
    color: white;

    align-items: center;
    opacity: 1;
    transition: opacity .3s ease, top .5s ease-in-out, background-image 1s ease-in .2s, color .8s ease-out .4s, height .4s ease, width 1s ease;

    background-image: linear-gradient(to top, #023352ea, #0233526f, rgba(2, 51, 82, 0.2), rgba(255, 255, 255, .1), rgba(255, 255, 255, 0));
    top: -10vh;
    height: 10vh;
    width: 100vw;
    justify-content: flex-end;
    padding-bottom: 10px;
}

.Footer-wheel-Hide {
    opacity: 0;

}

/* .Up_show>.Footer-wheel {
    background-image: linear-gradient(to bottom, #dfdfdf70, #06283D00);
    top: 0vh;
    width: 50px;
    height: 100%;
    justify-content: flex-start;

}

.Down_hide>.Footer-wheel {
    background-image: linear-gradient(to top, #023352ea, #0233526f, rgba(255, 255, 255, .1));
    box-shadow: 0px -10px 30px 0px rgba(255, 255, 255, .1);
    top: -10vh;
    height: 10vh;
    width: 100vw;
    justify-content: flex-end;

} */

.Footer-wheel>.Wheel-icon {
    font-size: 27px;
}

.Footer-wheel>.Wheel-icon_show {
    font-size: 25px;
    opacity: 1;
    transition: opacity .4s ease, font-size .5s ease-out .1s;
    -webkit-animation: wheel 1.5s ease-in-out .5s infinite;
            animation: wheel 1.5s ease-in-out .5s infinite;
}

.Footer-wheel>.Wheel-icon_hide {
    display: none;
    opacity: 0;
    font-size: 0px;
    transition: opacity .4s ease, font-size .5s ease-out .1s;
}

@-webkit-keyframes wheel {
    from {
        opacity: 0;
        color: rgb(0, 0, 0);
    }

    to {
        opacity: 1;
    }
}

@keyframes wheel {
    from {
        opacity: 0;
        color: rgb(0, 0, 0);
    }

    to {
        opacity: 1;
    }
}

.Footer-pattern {
    position: absolute;
    height: 100%;
    width: 100%;
    background: url(/FooterPattern.png) repeat 0% 0%;
    background-size: contain;
    mix-blend-mode: color-burn;
    z-index: -1;
    bottom: 0;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}
.Company-pattern {
    display: none;
}

.Footer-side {
    height: 100%;
    color: rgb(255, 255, 255);
    -webkit-text-stroke: hsl(41.9, 32.8%, 74.3%) .8px;
    font-size: calc(8px + .45vw + 0.225vh);

}

.Down_hide>.Footer-side {
    display: none;
}

.App-footer a {
    text-decoration: none;
    color: rgb(255, 255, 255);
    -webkit-text-stroke: hsl(41.9, 32.8%, 74.3%) 1px;
    font-weight: bolder;
}
.App-footer h2 {
    -webkit-text-stroke: hsl(41.9, 32.8%, 74.3%) 1px;
}
.App-footer h3 {
    -webkit-text-stroke: hsl(41.9, 32.8%, 74.3%) .8px;
}

.Seperate-side{
    margin: auto;
    height: 100%;
    width: 5px;
    box-shadow: -10px 0px 30px 5px hsl(41.9, 32.8%, 74.3%);
}
.Dev-side {
    width: 35vw;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

}

.Dev-declaration {
    padding: 10px 0;
}

.Dev-info {
    display: flex;
    padding: 0 30px;
    flex-grow: 1;
    width: 100%;
}

.Dev-info-main {
    display: flex;
    flex-flow: column;
    width: 100%;
    text-align: justify;
}

.Dev-button {
    flex-grow: 1;
    display: flex;
    justify-content: space-around;

}

.Link-Button-Wrapper {
    display: flex;
    align-items: center;
}

.Dev-side .Link-Button {
    font-size: calc(14px + .6vw + 0.3vh);
    padding-right: 5px;
}

.Link-Content {
    cursor: pointer;
    text-decoration: underline;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.Link-Content>p {
    text-decoration: none;
    position: absolute;
    padding: 10px;
    top: -200%;
    right: 0%;
    background-color: #1363DF;
    z-index: 20px;
    color: white;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;

}

.Dev-info-button {
    margin-left: 20px;
    display: flex;
    flex-flow: column;
    justify-content: space-evenly;
}

.Dev-side .Info-button {
    border-radius: 30%;
    height: 40px;
}

.Company-side {
    width: 65vw;
    display: flex;
    flex-flow: column;
    align-items: center;
    position: relative;

}
.Company-info {
    width: 100%;
    height: 60%;
    display: flex;
    text-align: left;
    justify-content: center;

}
.Company-logo-Wrapper {
    background: linear-gradient(-90deg, rgb(255, 255, 255),rgb(255, 255, 255),rgba(255, 255, 255, 0.8), rgba(255, 255, 255, .3), rgba(255, 255, 255, 0) );
    flex-grow: 1;
    position: relative;

}
.Company-logo {
    height:115%;
    width: auto;
    object-fit: contain;
    object-position: center;
    position: absolute;
    right: 10px;
    z-index: 10;
    -webkit-transform: scale(1.5) translateX(-15%) translateY(15%);
            transform: scale(1.5) translateX(-15%) translateY(15%);
}

.Company-text-Wrapper {
    display: flex;
    flex-flow: column;
    justify-content: space-evenly;
    padding-left: 20px;
    background: linear-gradient(175deg, #04395b, #043a5baa, #043a5b44, #043a5b00);
    /* line-height: 2.5rem; */
    width: 75%;
}
.Company-button {
    width: 100%;
    height: 40%;
    display: flex;
}
.Company-sign-Wrapper {
    background: linear-gradient(-70deg, rgb(255, 255, 255),rgb(255, 255, 255),rgba(255, 255, 255, 0.8), rgba(255, 255, 255, .3), rgba(255, 255, 255, 0) );
    height: 100%;
    flex-grow: 1;
    display: flex;
    align-items: center;
    position: relative;
}
.Company-sign {
    height: 70%;
    width: auto;
    object-fit: contain;
    object-position: center;
    position: absolute;
    right: 20px;
}
.Company-button-Wrapper {
    width: 75%;
    display: flex;
    justify-content: flex-start;
}
.Button-Content {
    margin-left: 20px;
    display: flex;
    flex-grow: 1;

}
.Button-Icon {
    display: flex;
    width: 20%;
    justify-content: space-evenly;
}
.Company-side .Info-button {
    border-radius: 30%;
    height: 50px;
}
.Company-side .Link-Button-Wrapper {
    margin-right: 20px;
}
.Company-side .Link-Button {
    font-size: calc(14px + .8vw + 0.4vh);
    padding-right: 10px;
}

@media only screen and (min-width: 950px) and (max-width: 1400px)  {
    .Footer-side {
        font-size: calc(10px + .4vw + 0.2vh);
    
    }
    .App-footer h2{
        font-size: calc(14px + 0.4vw + 0.2vh);
    }
    .App-footer h3 {
        font-size: calc(10px + 0.4vw + 0.2vh);

    }
    .Company-logo-Wrapper {
        background-color: rgba(255, 255, 255, 0.6);
        display: flex;
        justify-content: center;
        align-items: center;
    
    }
    .Company-logo {
        position: static;
        -webkit-transform: scale(1.5) translateX(0%) translateY(20%);
                transform: scale(1.5) translateX(0%) translateY(20%);
    }
    .Company-text-Wrapper {
        padding-left: 10px;
        background: linear-gradient(175deg, #04395b, #043a5baa, #043a5b44, #043a5b00);
        width: 70%;
    }
    .Company-sign-Wrapper {
        background-color: rgba(255, 255, 255, 0.6);

    }
    .Company-sign {
        height: 60%;
        right: 10px;
    }
    .Company-button-Wrapper {
        width: 70%;
        display: flex;
        justify-content: flex-start;
    }
    .Button-Content {
        margin-left: 20px;
        flex-flow: column;   
    }
    .Button-Icon {
        display: flex;
        width: 50%;
        justify-content: space-evenly;
    }
    .Company-side .Link-Button {
        font-size: calc(12px + .7vw + 0.3vh);
        padding-right: 10px;
    }
    .Dev-info-main {
        flex-grow: 1;
    }
    .Dev-contact {
        height: 20px;
        overflow: hidden;
    }
    .Dev-contact>div{
        white-space: nowrap;
        width: 200px;
        -webkit-animation: contactMoveToLeft 14s ease-in infinite;
                animation: contactMoveToLeft 14s ease-in infinite;
    }
    
    .Dev-button {
        flex-flow: column;
    }
    .Dev-side h3 {
        font-size: calc(14px + 0.4vw + 0.2vh);
        font-weight: normal;
    }
}

@media only screen and (min-width: 780px) and (max-width: 950px)  {
    .Footer-side {
        font-size: calc(10px + .4vw + 0.2vh);
    
    }
    .App-footer h2{
        font-size: calc(14px + 0.4vw + 0.2vh);
    }
    .App-footer h3 {
        font-size: calc(9px + 0.4vw + 0.2vh);

    }
    .Company-logo-Wrapper {
        background-color: rgb(255, 255, 255);
        display: flex;
        justify-content: center;
        align-items: center;
    
    }
    .Company-logo {
        position: static;
        -webkit-transform: scale(1.2) translateY(20%);
                transform: scale(1.2) translateY(20%);
    }
    .Company-text-Wrapper {
        padding-left: 10px;
        background: linear-gradient(175deg, #04395b, #043a5baa, #043a5b44, #043a5b00);
        width: 70%;
    }
    .Company-sign-Wrapper {
        background-color: rgb(255, 255, 255);
        justify-content: center;

    }
    .Company-sign {
        height: 60%;
        position: static;
    }
    .Company-button-Wrapper {
        width: 70%;
        display: flex;
        justify-content: flex-start;
    }
    .Button-Content {
        margin-left: 20px;
        flex-flow: column;   
    }
    .Button-Icon {
        display: flex;
        width: 50%;
        justify-content: space-evenly;
    }
    .Company-side .Link-Button {
        font-size: calc(12px + .7vw + 0.3vh);
        padding-right: 10px;
    }
    .Dev-info {
        padding: 0 10px;
    }
    .Dev-info-main {
        flex-grow: 1;
    }
    .Dev-contact {
        height: 20px;
        overflow: hidden;
    }
    .Dev-contact>div{
        white-space: nowrap;
        width: 200px;
        -webkit-animation: contactMoveToLeft 10s ease infinite;
                animation: contactMoveToLeft 10s ease infinite;
    }
    
    .Dev-button {
        flex-flow: column;
    }
    .Dev-side h3 {
        font-size: calc(14px + 0.4vw + 0.2vh);
        font-weight: normal;
    }
}
@media only screen and (min-width: 450px) and (max-width: 780px)  {
    .App-footer {
        display: flex;
        flex-flow: column;
        justify-content: space-between;
    }
    .Up_show {
        height: 20vh;
        min-height: 230px;
    }
    .Footer-side {
        font-size: calc(12px + .5vw + 0.3vh);
        width: 100%;
    }
    .Company-side {
        height: 70%;
    }
    .Dev-side {
        height: 30%;
    }
    .Seperate-side {
        width: 100%;
        height: 2px;
        margin: 5px 0;
    }
    .App-footer h2{
        font-size: calc(14px + 0.5vw + 0.25vh);
    }
    .App-footer h3 {
        font-size: calc(10px + 0.5vw + 0.25vh);

    }
    .Company-info {
        height: 60%;
        overflow: visible;

    }
    .Company-button {
        height: 40%;
    }
    .Company-logo-Wrapper {
        background-color: rgb(255, 255, 255);
        display: flex;
        justify-content: center;
        align-items: center;
    
    }
    .Company-logo {
        position: absolute;
        height: 150%;
        top: -10px;
        left: 0;
        z-index: 10;
        -webkit-transform: translateX(15%) translateY(10%);
                transform: translateX(15%) translateY(10%);
    }
    .Company-text-Wrapper {
        padding-left: 10px;
        background: linear-gradient(175deg, #04395b, #043a5baa, #043a5b44, #043a5b00);
        width: 70%;
    }
    .Company-sign-Wrapper {
        background-color: rgb(255, 255, 255);
        justify-content: center;

    }
    .Company-sign {
        height: 60%;
        position: absolute;
        right: 5px;
        bottom: 5px;
    }
    .Company-button-Wrapper {
        width: 70%;
        display: flex;
        justify-content: flex-start;
    }
    .Button-Content {
        margin-left: 20px;
        flex-flow: column;   
    }
    .Button-Icon {
        display: flex;
        width: 50%;
        justify-content: space-evenly;
    }
    .Company-side .Link-Button {
        font-size: calc(12px + .7vw + 0.3vh);
        padding-right: 10px;
    }
    .Dev-declaration {
        padding: 0;
    }
    .Dev-info {
        padding: 0 10px;
        padding-bottom: 10px;

    }
    .Dev-info-main {
        flex-grow: 1;
    }
    .Dev-contact {
        height: 20px;
        overflow: hidden;
    }
    .Dev-contact>div{
        white-space: nowrap;
        width: 200px;
        -webkit-animation: contactMoveToLeft 14s ease infinite;
                animation: contactMoveToLeft 14s ease infinite;
    }
    .Dev-info-button {
        flex-flow: row;
        width: 30%;
        margin: 0;
    }
    .Dev-button {
        flex-flow: row;
    }
    .Dev-side h3 {
        font-size: calc(14px + 0.4vw + 0.2vh);
        font-weight: normal;
    }
}

@media only screen and (min-width: 450px) and (max-width: 680px)  {
    
    .Company-logo-Wrapper {
        background-color: rgb(255, 255, 255);
        display: flex;
        justify-content: center;
        align-items: center;
    
    }
    .Company-logo {
        position: absolute;
        height: 120%;
        width: 100%;
        top: 0;
        right: 0;
        z-index: 10;
        -webkit-transform: none;
                transform: none;
    }
    .Company-sign-Wrapper {
        background-color: rgb(255, 255, 255);
        justify-content: center;

    }
    .Company-sign {
        height: 65%;
        width: 100%;
        position: absolute;
        right: 0;
        bottom: 5px;
    }
    
}
@media only screen and (max-width: 450px)  {
    .App-footer {
        display: flex;
        flex-flow: column;
        justify-content: space-between;
    }
    .Up_show {
        max-height: 40vh;
        height: 300px;
        padding-bottom: 5px;
    }
    .Footer-pattern {
        position: absolute;
        height: 35%;
        width: 100%;
        background: url(/FooterPattern.png) repeat 0% 0%;
        background-size: contain;
        mix-blend-mode: color-burn;
        z-index: -1;
        bottom: 0;
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
    }
    .Footer-side {
        font-size: calc(10px + .5vw + 0.3vh);
        width: 100%;
    }
    .Company-side {
        height: 65%;
    }
    .Dev-side {
        height: 30%;
    }
    .Seperate-side {
       display: none;
    }
    .App-footer h2{
        font-size: calc(14px + 0.6vw + 0.3vh);
    }
    .App-footer h3 {
        font-size: calc(12px + 0.575vw + 0.275vh);

    }
    .Company-info {
        height: 50%;
        overflow: visible;

    }
    .Company-button {
        height: 50%;
        flex-flow: column-reverse;
    }
    .Company-logo-Wrapper {
        background-color: rgb(255, 255, 255);
        display: flex;
        justify-content: center;
        align-items: center;
    
    }
    .Company-logo {
        position: absolute;
        height: 110%;
        width: 100%;
        top: -5px;
        right: 0;
        z-index: 10;
        -webkit-transform: none;
                transform: none;
    }
   
    .Company-text-Wrapper {
        padding-left: 10px;
        background: linear-gradient(175deg, #04395b, #043a5baa, #043a5b44, #043a5b00);
        width: 70%;
    }
    .Company-address {
        overflow: hidden;
    }
    .Company-address div {
        white-space: nowrap;
        width: 200px;
        -webkit-animation: addressMoveToLeft 16s ease infinite;
                animation: addressMoveToLeft 16s ease infinite;
    }
    .Company-sign-Wrapper {
        background: linear-gradient(to left, rgba(255, 255, 255, 0), white, white, white, rgba(255, 255, 255, 0));
        justify-content: center;
        height: 50%;
        margin: 0;

    }
    .Company-sign {
        height: 100%;
        width: 100%;
        position: absolute;
        right: 0;
        top: 0;
    }
    .Company-button-Wrapper {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        margin: 5px 0;
    }
    .Button-Content {
        margin-left: 20px;
        flex-flow: column;   
    }
    .Button-Icon {
        display: flex;
        width: 50%;
        justify-content: space-evenly;
    }
    .Company-side .Link-Button {
        font-size: calc(12px + .7vw + 0.3vh);
        padding-right: 10px;
    }
    .Company-pattern {
        position: absolute;
        display: block;
        height: 100%;
        width: 100%;
        background: url(/FooterPattern.png) repeat 50% 0%;
        background-size: contain;
        mix-blend-mode: color-burn;
        z-index: -1;
        /* bottom: calc(-20% / 3); */
        top: 0;
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
    }
    .Dev-declaration {
        padding: 0;
    }
    .Dev-info {
        padding: 0 10px;
        padding-bottom: 10px;

    }
    .Dev-info-main {
        flex-grow: 1;
    }
    .Dev-contact {
        height: 20px;
        overflow: hidden;
    }
    .Dev-contact>div{
        white-space: nowrap;
        width: 200px;
        -webkit-animation: contactMoveToLeft 14s ease-in infinite;
                animation: contactMoveToLeft 14s ease-in infinite;
    }
    .Dev-info-button {
        flex-flow: row;
        align-items: flex-end;
        width: 30%;
        margin: 0;
    }
    .Dev-button {
        flex-flow: column;
    }
    .Dev-side h3 {
        font-size: calc(14px + 0.4vw + 0.2vh);
        font-weight: normal;
    }
    .Company-side .Info-button {
        height: 35px;
    }
    .Dev-side .Info-button {
        height: 35px;
    }
}
@-webkit-keyframes contactMoveToLeft {
        0% {
            -webkit-transform: translateX(130%);
                    transform: translateX(130%);
        }
        100% {
            -webkit-transform: translateX(-350%);
                    transform: translateX(-350%);
        }
    }
@keyframes contactMoveToLeft {
        0% {
            -webkit-transform: translateX(130%);
                    transform: translateX(130%);
        }
        100% {
            -webkit-transform: translateX(-350%);
                    transform: translateX(-350%);
        }
    }
    @-webkit-keyframes addressMoveToLeft {
        0% {
            -webkit-transform: translateX(100%);
                    transform: translateX(100%);
        }
        100% {
            -webkit-transform: translateX(-300%);
                    transform: translateX(-300%);
        } 
    }
    @keyframes addressMoveToLeft {
        0% {
            -webkit-transform: translateX(100%);
                    transform: translateX(100%);
        }
        100% {
            -webkit-transform: translateX(-300%);
                    transform: translateX(-300%);
        } 
    }
