.App-contact>img {
    height: 100%;
    width: 100vw;
    position: absolute;
    z-index: 0;
    object-fit: cover;
}

.Contact-background {
    position: fixed;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
}

.Contact-form-container {
    height: calc(100% - 6vh - 20px);
    width: 100vw;
    box-shadow: rgb(1 17 36) 0px 5px 12px 1px;
    z-index: 1;
    display: flex;
    justify-content: center;
    background-color: #47B5FF2b;
}

.Contact-form-model {
    height: 100%;
    width: fit-content;
    background: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(255, 255, 255, .1), rgba(0, 0, 0, 0));
    backdrop-filter: blur(5px);
    color: white;
    padding: 0 5%;
    display: flex;
    flex-flow: column;
}

.Contact-form-model>p {
    font-size: calc(12px + .5vw + .3vh);
    padding: 10px;
    background-color: #47B5FF2b;
}

.Contact-form-model>p>span {
    font-weight: bold;
    font-style: italic;
}

.Form-outer {
    flex-grow: 1;
    width: fit-content;
    display: flex;
    flex-flow: column;
    overflow-y: scroll;
    overflow-x: hidden;
    mix-blend-mode: color-dodge;
    background: linear-gradient(to left, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1));
    backdrop-filter: blur(80px);
    padding-bottom: 40px;

}

.Dodge-footer {
    padding-bottom: calc(200px);
}

.Form-outer::-webkit-scrollbar {
    width: 0px;
}



.Form-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: calc(100vw - 240px);
    padding: 0 120px;
}

.Submit-button {
    width: 100%;
    display: flex;
    justify-content: right;
    margin-top: 50px;

}

.Submit-button-inactive {
    width: 100%;
    display: flex;
    justify-content: right;
    margin-top: 50px;

}

.Submit-button button {
    outline: none;
    width: 200px;
    height: 80px;
    color: white;
    border: 1px solid transparent;
    border-radius: 15px;
    font-size: calc(12px + .6vw + .3vh);
    background-color: #1363DF;
    cursor: pointer;
    box-shadow: 0px -3px 5px 1px #ffffffa5;
    transition: all 1s;
    margin-right: 60px;
}

.Submit-button-inactive button {
    outline: none;
    width: 200px;
    height: 80px;
    color: white;
    border: 1px solid transparent;
    border-radius: 15px;
    font-size: calc(12px + .6vw + .3vh);
    background-color: #303f55;
    box-shadow: 0px -3px 5px 1px #ffffffa5;
    transition: all 1s;
    margin-right: 60px;

}

.Submit-button button:hover {
    box-shadow: 0px -5px 20px 1px #0dc3f0 inset, 0px 5px 10px 1px #ffffff, 0px 0px 20px 5px rgba(255, 255, 255, 0.4) inset;
}

.Form-side {
    max-width: calc(50% - 20px);
    height: fit-content;
}

.Form-side:first-child {
    width: 45%;
}

.Form-footer {
    width: 100%;
    display: flex;
}

.Form-Element {
    border: none;
    padding: 20px 30px;
    box-shadow: 0px -5px 20px 1px #0dc3f0 inset, 0px 5px 10px 1px #ffffff, 0px 0px 20px 5px rgba(255, 255, 255, 0.4) inset;
    border-radius: 50px;
    margin-top: 65px;

}

.Form-Element>legend {
    font-weight: bolder;
    border-left: 5px solid #1363DF;
    border-bottom: none;
    padding: 15px 30px 15px 15px;
    transform: translate(0%, -50%);
    text-align: left;
    margin-left: 15px;
    border-radius: 10px 60px 0px 0;
    background: linear-gradient(to top, rgba(4, 34, 100, 0.1), #ffffff70);
    color: #1363DF;
    -webkit-text-stroke-width: .5px;
    box-shadow: 5px 5px 20px 0px #0dc3f0 inset, -5px 5px 20px 0px #0dc3f0 inset, 0px -5px 15px 3px #ffffff;
    max-width: calc(100% - 30px);
    animation: changeBoxShadow 6s ease-in-out 0s infinite;
}

.Form-Element>legend>div {
    /* background: linear-gradient(to left, #1363DF, white); */
    animation: changeBackgroundGradient 6s ease 0s infinite;

}

.Form-Element>legend>div {
    font-size: calc(10px + .6vw + .3vh);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

@keyframes changeBackgroundGradient {
    0% {
        background: linear-gradient(to left, #3292ff, white);
        background-clip: text;


    }

    25% {
        background: white;
        background-clip: text;
        color: #ffffff;

    }

    50% {
        background: linear-gradient(to right, #3292ff, white);
        background-clip: text;


    }

    75% {
        background: white;
        background-clip: text;
        color: #ffffff;

    }

    100% {
        background: linear-gradient(to left, #3292ff, white);
        background-clip: text;


    }
}

@keyframes changeBoxShadow {
    0% {
        box-shadow: 5px 5px 20px 1px #0dc3f0 inset, -5px 5px 20px 1px #0dc3f0 inset, 0px -5px 15px 3px #ffffff;
    }

    25% {
        box-shadow: -5px 5px 20px 1px #0dc3f0 inset, -5px -5px 20px 1px #0dc3f0 inset, 5px 0px 10px 1px #ffffff;
    }

    50% {
        box-shadow: 5px -5px 20px 1px #0dc3f0 inset, -5px -5px 20px 1px #0dc3f0 inset, 0px 5px 15px 3px #ffffff;
    }

    75% {
        box-shadow: 5px 5px 20px 1px #0dc3f0 inset, 5px -5px 20px 1px #0dc3f0 inset, -5px 0px 10px 1px #ffffff;
    }

    100% {
        box-shadow: 5px 5px 20px 1px #0dc3f0 inset, -5px 5px 20px 1px #0dc3f0 inset, 0px -5px 15px 3px #ffffff;
    }
}

.Form-input-wrapper {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    margin-top: 30px;
    transform: translateY(-40px);
    font-size: calc(6px + .3vw + .2vh);

}

.Form-input-wrapper>div {
    display: flex;
    width: 100%;

}

.Form-side input {
    outline: none;
    flex-grow: 1;
    color: white;
    background: linear-gradient(to top, #47b5ffa6, rgba(0, 0, 0, 0));
    border: none;
    border-bottom: 1px solid white;
    padding: 5px 20px 5px 5px;
    border-bottom-right-radius: 20px;
    width: 50px;
    font-size: calc(8px + .6vw + .3vh);

}

.Form-side select {
    font-size: calc(8px + .6vw + .3vh);
    outline: none;
    color: white;
    background: linear-gradient(to top, #47b5ffa6, rgba(0, 0, 0, 0));
    border: none;
    border-bottom: 1px solid white;
    padding: 5px 0px 5px 10px;
    border-bottom-left-radius: 20px;
    width: fit-content;

}

.Form-side option {
    color: blue;
    background-image: linear-gradient(to top, #47b5ffa6, rgba(0, 0, 0, 0));
    font-size: calc(8px + .6vw + .3vh);
}


.Form-input-wrapper h3 {
    text-align: left;
    margin-bottom: 10px;
}

.Form-input-wrapper h3>span {
    background-color: white;
    color: #06283D;
    padding: 5px 10px;
}

.Form-input-wrapper_email input {
    border-bottom-left-radius: 20px;
    padding: 5px 15px;

}

.Form-input-wrapper_address input {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 20px;
    padding: 5px 5px 5px 15px;



}

.Form-input-wrapper_address select {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 20px;
    padding: 5px;

}



.Form-checkbox {
    display: flex;
    flex-flow: row wrap;
}

.Form-checkbox>div {
    flex-flow: row wrap;
    font-size: calc(6px + .5vw + .2vh);

}

.Form-checkbox>div>div {
    cursor: pointer;
    padding: 10px 15px;
    margin: 0px 7.5px;
    margin: 10px;
    border: 2px solid white;
    border-radius: 15px;
    background-image: linear-gradient(to top, #47b5ffa6, rgba(0, 0, 0, 0));
    transition: background-color .3s ease, border .5s ease-in-out, box-shadow .5s ease-out;
}

.Form-checkbox>div>div>label {
    cursor: pointer;
}

.Form-checkbox .Checked-box {
    background-image: none;
    background-color: #1363DF;
    border: 2px solid #0dc3f0;
    border-bottom: 2px solid #1363DF;
    box-shadow: 0px 5px 20px 1px #0dc3f0 inset, 0px 0px 15px 5px #d1f1ff;

}
.Checked-box>input {
    display: none;
}

.Form-checkbox input {
    width: 0;
}

.Form-input-wrapper h4 {
    color: rgb(209, 10, 0);
    text-shadow: 0px 0px 30px white;
    font-size: calc(10px + .4vw + .2vh);
    -webkit-text-stroke: rgb(255, 81, 81) .5px;
    margin: 10px 15px;
    padding: 5px 10px;
    background: linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.2));
    box-shadow: 0px -4px 7px 1px black inset;
    font-weight: 400;
}



@media screen and (max-width: 450px) {

    .Contact-form-model {
        background-color: #06283d5b;
    }

    .Form-outer {
        width: 100vw;
        max-width: 100vw;
        padding: 50px 0px 70px 0px;

    }

    .Dodge-footer {
        padding-bottom: calc(30vh + 100px) !important;
    }

    .Form-container {
        flex-flow: column;
        width: 100vw;
        padding: 0px;
    }

    .Form-side {
        max-width: 100% !important;
        width: 100% !important;
    }

    .Form-Element {
        border: none;
        padding: 20px 30px;
        box-shadow: none;
        border-radius: 0px;
        margin-top: 0px;
    }

    .Form-Element:last-child {
        padding: 30px 20px 0 30px
    }

    .Form-footer {
        flex-flow: column;
    }

    .Form-checkbox>div>div {
        cursor: pointer;
        padding: 5px 15px 10px 15px;

    }

    .Submit-button {
        margin-top: 0px;
    }

    .Submit-button-inactive {
        margin-top: 0px;
    }

    .Submit-button button {
        width: 120px;
        height: 60px;
        margin-right: 10%;
    }

    .Submit-button-inactive button {
        width: 120px;
        height: 60px;
        margin-right: 10%;
    }
}

@media only screen and (min-width: 450px) and (max-width: 780px) {

    .Contact-form-model {
        background-color: #06283d5b;
    }

    .Form-outer {
        width: 100vw;
        max-width: 100vw;
        padding: 50px 10px 50px 10px;

    }

    .Dodge-footer {
        padding-bottom: calc(30vh) !important;
    }

    .Form-container {
        flex-flow: column;
        width: 100vw;
        padding: 0px;
    }

    .Form-side {
        max-width: 100% !important;
        width: 100% !important;
    }

    .Form-Element {
        border: none;
        padding: 20px 50px;
        box-shadow: none;
        border-radius: 0px;
        margin-top: 0px;
    }

    .Form-Element:last-child {
        padding-bottom: 0px;
    }

    .Form-footer {
        flex-flow: column;
    }

    .Form-checkbox>div>div {
        cursor: pointer;
        padding: 5px 15px 10px 15px;

    }

    .Submit-button {
        margin-top: 0px;
    }

    .Submit-button-inactive {
        margin-top: 0px;
    }

    .Submit-button button {
        width: 140px;
        height: 70px;
        margin-right: 15%;
    }

    .Submit-button-inactive button {
        width: 140px;
        height: 70px;
        margin-right: 15%;
    }
}

@media screen and (min-width: 780px) and (max-width: 980px) {
    .Contact-form-model {
        background-color: #06283d5b;
    }

    .Form-outer {
        width: 100vw;
        max-width: 100vw;
        padding: 50px 10px;
    }

    .Dodge-footer {
        padding-bottom: calc(20vh + 50px) !important;
    }

    .Form-container {
        flex-flow: column;
        width: 100vw;
        padding: 0px 5%;
    }

    .Form-side {
        max-width: 100% !important;
        width: 100% !important;
    }

    .Form-Element {
        border: none;
        padding: 20px 50px;
        box-shadow: none;
        border-radius: 0px;
        margin-top: 0px;
    }

    .Form-Element:last-child {
        padding-bottom: 0px;
    }

    .Form-footer {
        flex-flow: column;
    }

    .Submit-button {
        margin-top: 0px;
    }

    .Submit-button-inactive {
        margin-top: 0px;
    }

    .Submit-button button {
        width: 160px;
        height: 80px;
        margin-right: 15%;
    }

    .Submit-button-inactive button {
        width: 160px;
        height: 80px;
        margin-right: 15%;
    }


}

@media screen and (min-width: 980px) and (max-width: 1200px) {
    .Form-container {
        width: 100vw;
        padding: 0 5%;
    }
}