.Footer-wheel {
    position: absolute;
    display: flex;
    flex-flow: column;
    color: white;

    align-items: center;
    opacity: 1;
    transition: opacity .3s ease, top .5s ease-in-out, background-image 1s ease-in .2s, color .8s ease-out .4s, height .4s ease, width 1s ease;

    background-image: linear-gradient(to top, #023352ea, #0233526f, rgba(2, 51, 82, 0.2), rgba(255, 255, 255, .1), rgba(255, 255, 255, 0));
    top: -10vh;
    height: 10vh;
    width: 100vw;
    justify-content: flex-end;
    padding-bottom: 10px;
}

.Footer-wheel-Hide {
    opacity: 0;

}

/* .Up_show>.Footer-wheel {
    background-image: linear-gradient(to bottom, #dfdfdf70, #06283D00);
    top: 0vh;
    width: 50px;
    height: 100%;
    justify-content: flex-start;

}

.Down_hide>.Footer-wheel {
    background-image: linear-gradient(to top, #023352ea, #0233526f, rgba(255, 255, 255, .1));
    box-shadow: 0px -10px 30px 0px rgba(255, 255, 255, .1);
    top: -10vh;
    height: 10vh;
    width: 100vw;
    justify-content: flex-end;

} */

.Footer-wheel>.Wheel-icon {
    font-size: 27px;
}

.Footer-wheel>.Wheel-icon_show {
    font-size: 25px;
    opacity: 1;
    transition: opacity .4s ease, font-size .5s ease-out .1s;
    animation: wheel 1.5s ease-in-out .5s infinite;
}

.Footer-wheel>.Wheel-icon_hide {
    display: none;
    opacity: 0;
    font-size: 0px;
    transition: opacity .4s ease, font-size .5s ease-out .1s;
}

@keyframes wheel {
    from {
        opacity: 0;
        color: rgb(0, 0, 0);
    }

    to {
        opacity: 1;
    }
}

.Footer-pattern {
    position: absolute;
    height: 100%;
    width: 100%;
    background: url(/FooterPattern.png) repeat 0% 0%;
    background-size: contain;
    mix-blend-mode: color-burn;
    z-index: -1;
    bottom: 0;
    transform: rotate(180deg);
}
.Company-pattern {
    display: none;
}

.Footer-side {
    height: 100%;
    color: rgb(255, 255, 255);
    -webkit-text-stroke: hsl(41.9, 32.8%, 74.3%) .8px;
    font-size: calc(8px + .45vw + 0.225vh);

}

.Down_hide>.Footer-side {
    display: none;
}

.App-footer a {
    text-decoration: none;
    color: rgb(255, 255, 255);
    -webkit-text-stroke: hsl(41.9, 32.8%, 74.3%) 1px;
    font-weight: bolder;
}
.App-footer h2 {
    -webkit-text-stroke: hsl(41.9, 32.8%, 74.3%) 1px;
}
.App-footer h3 {
    -webkit-text-stroke: hsl(41.9, 32.8%, 74.3%) .8px;
}

.Seperate-side{
    margin: auto;
    height: 100%;
    width: 5px;
    box-shadow: -10px 0px 30px 5px hsl(41.9, 32.8%, 74.3%);
}
.Dev-side {
    width: 35vw;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

}

.Dev-declaration {
    padding: 10px 0;
}

.Dev-info {
    display: flex;
    padding: 0 30px;
    flex-grow: 1;
    width: 100%;
}

.Dev-info-main {
    display: flex;
    flex-flow: column;
    width: 100%;
    text-align: justify;
}

.Dev-button {
    flex-grow: 1;
    display: flex;
    justify-content: space-around;

}

.Link-Button-Wrapper {
    display: flex;
    align-items: center;
}

.Dev-side .Link-Button {
    font-size: calc(14px + .6vw + 0.3vh);
    padding-right: 5px;
}

.Link-Content {
    cursor: pointer;
    text-decoration: underline;
    position: relative;
    width: fit-content;
}
.Link-Content>p {
    text-decoration: none;
    position: absolute;
    padding: 10px;
    top: -200%;
    right: 0%;
    background-color: #1363DF;
    z-index: 20px;
    color: white;
    width: fit-content;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;

}

.Dev-info-button {
    margin-left: 20px;
    display: flex;
    flex-flow: column;
    justify-content: space-evenly;
}

.Dev-side .Info-button {
    border-radius: 30%;
    height: 40px;
}

.Company-side {
    width: 65vw;
    display: flex;
    flex-flow: column;
    align-items: center;
    position: relative;

}
.Company-info {
    width: 100%;
    height: 60%;
    display: flex;
    text-align: left;
    justify-content: center;

}
.Company-logo-Wrapper {
    background: linear-gradient(-90deg, rgb(255, 255, 255),rgb(255, 255, 255),rgba(255, 255, 255, 0.8), rgba(255, 255, 255, .3), rgba(255, 255, 255, 0) );
    flex-grow: 1;
    position: relative;

}
.Company-logo {
    height:115%;
    width: auto;
    object-fit: contain;
    object-position: center;
    position: absolute;
    right: 10px;
    z-index: 10;
    transform: scale(1.5) translateX(-15%) translateY(15%);
}

.Company-text-Wrapper {
    display: flex;
    flex-flow: column;
    justify-content: space-evenly;
    padding-left: 20px;
    background: linear-gradient(175deg, #04395b, #043a5baa, #043a5b44, #043a5b00);
    /* line-height: 2.5rem; */
    width: 75%;
}
.Company-button {
    width: 100%;
    height: 40%;
    display: flex;
}
.Company-sign-Wrapper {
    background: linear-gradient(-70deg, rgb(255, 255, 255),rgb(255, 255, 255),rgba(255, 255, 255, 0.8), rgba(255, 255, 255, .3), rgba(255, 255, 255, 0) );
    height: 100%;
    flex-grow: 1;
    display: flex;
    align-items: center;
    position: relative;
}
.Company-sign {
    height: 70%;
    width: auto;
    object-fit: contain;
    object-position: center;
    position: absolute;
    right: 20px;
}
.Company-button-Wrapper {
    width: 75%;
    display: flex;
    justify-content: flex-start;
}
.Button-Content {
    margin-left: 20px;
    display: flex;
    flex-grow: 1;

}
.Button-Icon {
    display: flex;
    width: 20%;
    justify-content: space-evenly;
}
.Company-side .Info-button {
    border-radius: 30%;
    height: 50px;
}
.Company-side .Link-Button-Wrapper {
    margin-right: 20px;
}
.Company-side .Link-Button {
    font-size: calc(14px + .8vw + 0.4vh);
    padding-right: 10px;
}

@media only screen and (min-width: 950px) and (max-width: 1400px)  {
    .Footer-side {
        font-size: calc(10px + .4vw + 0.2vh);
    
    }
    .App-footer h2{
        font-size: calc(14px + 0.4vw + 0.2vh);
    }
    .App-footer h3 {
        font-size: calc(10px + 0.4vw + 0.2vh);

    }
    .Company-logo-Wrapper {
        background-color: rgba(255, 255, 255, 0.6);
        display: flex;
        justify-content: center;
        align-items: center;
    
    }
    .Company-logo {
        position: static;
        transform: scale(1.5) translateX(0%) translateY(20%);
    }
    .Company-text-Wrapper {
        padding-left: 10px;
        background: linear-gradient(175deg, #04395b, #043a5baa, #043a5b44, #043a5b00);
        width: 70%;
    }
    .Company-sign-Wrapper {
        background-color: rgba(255, 255, 255, 0.6);

    }
    .Company-sign {
        height: 60%;
        right: 10px;
    }
    .Company-button-Wrapper {
        width: 70%;
        display: flex;
        justify-content: flex-start;
    }
    .Button-Content {
        margin-left: 20px;
        flex-flow: column;   
    }
    .Button-Icon {
        display: flex;
        width: 50%;
        justify-content: space-evenly;
    }
    .Company-side .Link-Button {
        font-size: calc(12px + .7vw + 0.3vh);
        padding-right: 10px;
    }
    .Dev-info-main {
        flex-grow: 1;
    }
    .Dev-contact {
        height: 20px;
        overflow: hidden;
    }
    .Dev-contact>div{
        white-space: nowrap;
        width: 200px;
        animation: contactMoveToLeft 14s ease-in infinite;
    }
    
    .Dev-button {
        flex-flow: column;
    }
    .Dev-side h3 {
        font-size: calc(14px + 0.4vw + 0.2vh);
        font-weight: normal;
    }
}

@media only screen and (min-width: 780px) and (max-width: 950px)  {
    .Footer-side {
        font-size: calc(10px + .4vw + 0.2vh);
    
    }
    .App-footer h2{
        font-size: calc(14px + 0.4vw + 0.2vh);
    }
    .App-footer h3 {
        font-size: calc(9px + 0.4vw + 0.2vh);

    }
    .Company-logo-Wrapper {
        background-color: rgb(255, 255, 255);
        display: flex;
        justify-content: center;
        align-items: center;
    
    }
    .Company-logo {
        position: static;
        transform: scale(1.2) translateY(20%);
    }
    .Company-text-Wrapper {
        padding-left: 10px;
        background: linear-gradient(175deg, #04395b, #043a5baa, #043a5b44, #043a5b00);
        width: 70%;
    }
    .Company-sign-Wrapper {
        background-color: rgb(255, 255, 255);
        justify-content: center;

    }
    .Company-sign {
        height: 60%;
        position: static;
    }
    .Company-button-Wrapper {
        width: 70%;
        display: flex;
        justify-content: flex-start;
    }
    .Button-Content {
        margin-left: 20px;
        flex-flow: column;   
    }
    .Button-Icon {
        display: flex;
        width: 50%;
        justify-content: space-evenly;
    }
    .Company-side .Link-Button {
        font-size: calc(12px + .7vw + 0.3vh);
        padding-right: 10px;
    }
    .Dev-info {
        padding: 0 10px;
    }
    .Dev-info-main {
        flex-grow: 1;
    }
    .Dev-contact {
        height: 20px;
        overflow: hidden;
    }
    .Dev-contact>div{
        white-space: nowrap;
        width: 200px;
        animation: contactMoveToLeft 10s ease infinite;
    }
    
    .Dev-button {
        flex-flow: column;
    }
    .Dev-side h3 {
        font-size: calc(14px + 0.4vw + 0.2vh);
        font-weight: normal;
    }
}
@media only screen and (min-width: 450px) and (max-width: 780px)  {
    .App-footer {
        display: flex;
        flex-flow: column;
        justify-content: space-between;
    }
    .Up_show {
        height: 20vh;
        min-height: 230px;
    }
    .Footer-side {
        font-size: calc(12px + .5vw + 0.3vh);
        width: 100%;
    }
    .Company-side {
        height: 70%;
    }
    .Dev-side {
        height: 30%;
    }
    .Seperate-side {
        width: 100%;
        height: 2px;
        margin: 5px 0;
    }
    .App-footer h2{
        font-size: calc(14px + 0.5vw + 0.25vh);
    }
    .App-footer h3 {
        font-size: calc(10px + 0.5vw + 0.25vh);

    }
    .Company-info {
        height: 60%;
        overflow: visible;

    }
    .Company-button {
        height: 40%;
    }
    .Company-logo-Wrapper {
        background-color: rgb(255, 255, 255);
        display: flex;
        justify-content: center;
        align-items: center;
    
    }
    .Company-logo {
        position: absolute;
        height: 150%;
        top: -10px;
        left: 0;
        z-index: 10;
        transform: translateX(15%) translateY(10%);
    }
    .Company-text-Wrapper {
        padding-left: 10px;
        background: linear-gradient(175deg, #04395b, #043a5baa, #043a5b44, #043a5b00);
        width: 70%;
    }
    .Company-sign-Wrapper {
        background-color: rgb(255, 255, 255);
        justify-content: center;

    }
    .Company-sign {
        height: 60%;
        position: absolute;
        right: 5px;
        bottom: 5px;
    }
    .Company-button-Wrapper {
        width: 70%;
        display: flex;
        justify-content: flex-start;
    }
    .Button-Content {
        margin-left: 20px;
        flex-flow: column;   
    }
    .Button-Icon {
        display: flex;
        width: 50%;
        justify-content: space-evenly;
    }
    .Company-side .Link-Button {
        font-size: calc(12px + .7vw + 0.3vh);
        padding-right: 10px;
    }
    .Dev-declaration {
        padding: 0;
    }
    .Dev-info {
        padding: 0 10px;
        padding-bottom: 10px;

    }
    .Dev-info-main {
        flex-grow: 1;
    }
    .Dev-contact {
        height: 20px;
        overflow: hidden;
    }
    .Dev-contact>div{
        white-space: nowrap;
        width: 200px;
        animation: contactMoveToLeft 14s ease infinite;
    }
    .Dev-info-button {
        flex-flow: row;
        width: 30%;
        margin: 0;
    }
    .Dev-button {
        flex-flow: row;
    }
    .Dev-side h3 {
        font-size: calc(14px + 0.4vw + 0.2vh);
        font-weight: normal;
    }
}

@media only screen and (min-width: 450px) and (max-width: 680px)  {
    
    .Company-logo-Wrapper {
        background-color: rgb(255, 255, 255);
        display: flex;
        justify-content: center;
        align-items: center;
    
    }
    .Company-logo {
        position: absolute;
        height: 120%;
        width: 100%;
        top: 0;
        right: 0;
        z-index: 10;
        transform: none;
    }
    .Company-sign-Wrapper {
        background-color: rgb(255, 255, 255);
        justify-content: center;

    }
    .Company-sign {
        height: 65%;
        width: 100%;
        position: absolute;
        right: 0;
        bottom: 5px;
    }
    
}
@media only screen and (max-width: 450px)  {
    .App-footer {
        display: flex;
        flex-flow: column;
        justify-content: space-between;
    }
    .Up_show {
        max-height: 40vh;
        height: 300px;
        padding-bottom: 5px;
    }
    .Footer-pattern {
        position: absolute;
        height: 35%;
        width: 100%;
        background: url(/FooterPattern.png) repeat 0% 0%;
        background-size: contain;
        mix-blend-mode: color-burn;
        z-index: -1;
        bottom: 0;
        transform: rotate(180deg);
    }
    .Footer-side {
        font-size: calc(10px + .5vw + 0.3vh);
        width: 100%;
    }
    .Company-side {
        height: 65%;
    }
    .Dev-side {
        height: 30%;
    }
    .Seperate-side {
       display: none;
    }
    .App-footer h2{
        font-size: calc(14px + 0.6vw + 0.3vh);
    }
    .App-footer h3 {
        font-size: calc(12px + 0.575vw + 0.275vh);

    }
    .Company-info {
        height: 50%;
        overflow: visible;

    }
    .Company-button {
        height: 50%;
        flex-flow: column-reverse;
    }
    .Company-logo-Wrapper {
        background-color: rgb(255, 255, 255);
        display: flex;
        justify-content: center;
        align-items: center;
    
    }
    .Company-logo {
        position: absolute;
        height: 110%;
        width: 100%;
        top: -5px;
        right: 0;
        z-index: 10;
        transform: none;
    }
   
    .Company-text-Wrapper {
        padding-left: 10px;
        background: linear-gradient(175deg, #04395b, #043a5baa, #043a5b44, #043a5b00);
        width: 70%;
    }
    .Company-address {
        overflow: hidden;
    }
    .Company-address div {
        white-space: nowrap;
        width: 200px;
        animation: addressMoveToLeft 16s ease infinite;
    }
    .Company-sign-Wrapper {
        background: linear-gradient(to left, rgba(255, 255, 255, 0), white, white, white, rgba(255, 255, 255, 0));
        justify-content: center;
        height: 50%;
        margin: 0;

    }
    .Company-sign {
        height: 100%;
        width: 100%;
        position: absolute;
        right: 0;
        top: 0;
    }
    .Company-button-Wrapper {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        margin: 5px 0;
    }
    .Button-Content {
        margin-left: 20px;
        flex-flow: column;   
    }
    .Button-Icon {
        display: flex;
        width: 50%;
        justify-content: space-evenly;
    }
    .Company-side .Link-Button {
        font-size: calc(12px + .7vw + 0.3vh);
        padding-right: 10px;
    }
    .Company-pattern {
        position: absolute;
        display: block;
        height: 100%;
        width: 100%;
        background: url(/FooterPattern.png) repeat 50% 0%;
        background-size: contain;
        mix-blend-mode: color-burn;
        z-index: -1;
        /* bottom: calc(-20% / 3); */
        top: 0;
        transform: rotate(180deg);
    }
    .Dev-declaration {
        padding: 0;
    }
    .Dev-info {
        padding: 0 10px;
        padding-bottom: 10px;

    }
    .Dev-info-main {
        flex-grow: 1;
    }
    .Dev-contact {
        height: 20px;
        overflow: hidden;
    }
    .Dev-contact>div{
        white-space: nowrap;
        width: 200px;
        animation: contactMoveToLeft 14s ease-in infinite;
    }
    .Dev-info-button {
        flex-flow: row;
        align-items: flex-end;
        width: 30%;
        margin: 0;
    }
    .Dev-button {
        flex-flow: column;
    }
    .Dev-side h3 {
        font-size: calc(14px + 0.4vw + 0.2vh);
        font-weight: normal;
    }
    .Company-side .Info-button {
        height: 35px;
    }
    .Dev-side .Info-button {
        height: 35px;
    }
}
@keyframes contactMoveToLeft {
        0% {
            transform: translateX(130%);
        }
        100% {
            transform: translateX(-350%);
        }
    }
    @keyframes addressMoveToLeft {
        0% {
            transform: translateX(100%);
        }
        100% {
            transform: translateX(-300%);
        } 
    }