/* CAN NOT USE IN SAFARI MOBILE */
/* transform-style */
/* perspective */
/* backface-visibility */

.Product-container {
    height: fit-content;
    transition: transform .3s ease-in-out;
}

.Product-type-Screen {
    max-height: 80vh;
    height: 700px;
    width: 100vw;
    overflow: hidden;
    display: flex;
    justify-content: center;
    transition: box-shadow .5s ease-in-out .5s;
    padding-top: calc(4vh + 20px);
}

.Product-type-Active {
    box-shadow: rgb(44, 62, 80) 0px 15px 30px -20px;

}

.Product-type-Active>.Product-item-Wrapper {
    padding-bottom: 5%;
}

.Product-item-Wrapper {
    width: 0px;
    height: 100%;
    overflow-x: visible;
    align-items: flex-start;
    display: flex;
    justify-content: center;
    position: relative;
    padding-bottom: 5%;
    transition: padding .5s ease-in-out;

}

.Product-item-Slide {
    overflow-x: visible;
    transition: transform .2s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.Product-item-Container {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    transition: transform .3s ease-in-out .2s, background-color .3s ease-in;

}

.Product-item-Container-Scroll {
    transition: background-color .4s ease-out;
}

.Product-item-Cover {
    padding: 20px;

}

.Product-item-Cover_Non-safari {
    perspective: 2000px;
    transform-style: preserve-3d;
}


.Product-item {
    max-height: calc(80vh - 200px);
    height: 450px;
    width: 500px;
    max-width: 100vw;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    transition: transform .35s ease-out;
    position: relative;
    cursor: pointer;
}

.Product-item_Non-safari {
    transform-style: preserve-3d;
}

.Product-item-Cover-Hover>.Product-item {
    transform: rotateX(-180deg);
    box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 3px, rgba(0, 0, 0, 0.24) 0px 4px 4px;
}


.Item-image {
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    height: 100%;
    width: 500px;
    max-width: 100%;
    object-fit: contain;
    position: absolute;
    transition: box-shadow .3s ease-in-out .3s, transform .4s ease-in-out .1s, width .2s ease-in-out, height .2s ease-in-out, background-color .4s ease-out;
}
.Item-loading {
    color: rgb(0, 33, 105);
    font-size: calc(12px + .6vw + .3vh);
    font-weight: bolder;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
}
.Item-loading h2 {
    height: 50%;
}
.Product-item-Cover-Hover .Item-image {
    transform: rotateX(-180deg) rotateY(-360deg);
    width: 200px;
    max-width: 50%;
    height: 144px;
    bottom: 60px;
    left: 0;
    box-shadow: none;
    z-index: 91;
    transition: box-shadow .1s, transform .3s ease-in-out .2s, width .4s ease-out .1s, height .4s ease-out .1s;
}


.Item-name {
    width: 100%;
    height: 100%;
    position: absolute;
    transform: rotateX(180deg);
    padding: 10px 20px;
    bottom: 0%;
    backface-visibility: hidden;
    transition: all .3s ease-in-out .3s, color .3s ease-out .3s, box-shadow .3s ease-out .3s;
    color: rgb(255, 255, 255);
}

.Item-detail {
    backface-visibility: hidden;
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 60px;
    padding: 0 15px;
    color: rgb(0, 4, 46);
    transform: rotateX(180deg) scale(.3);

    transition: color .2s ease-in-out, opacity .1s ease-in .1s, transform .2s ease-in;
}


.Product-item-Cover-Hover .Item-detail {
    color: white;
    transform: rotateX(180deg) scale(1);
    transition: color .3s ease-in-out .1s, opacity .2s ease-out, transform .4s ease-out .1s;
}

.Item-detail-Mask {
    position: relative;
    float: left;
    clear: none;
    left: 0;
    top: 0;
    background-color: transparent;
    width: 200px;
    height: 144px;
    margin-bottom: 5px;
}

.Item-detail-Paragraph {
    text-align: justify;
    margin-bottom: calc(5px + 1vmin);
    font-size: calc(7px + 1.5vmin);
    text-indent: 10%;
}

.Product-item-Cover-Hover .Item-name {
    color: rgb(0, 33, 105);
    background-color: #47b5ff9b;
    z-index: -1;

}

.Item-guide {
    right: 0;
    position: absolute;
    backface-visibility: hidden;
    height: 20%;
    width: 100%;
    padding-bottom: 2%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: underline;
    z-index: 90;
    color: rgb(7, 63, 185);
    text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.8);
    background-image: linear-gradient(to top, #0d3755d3, #47b5ff00);
    backdrop-filter: blur(1.5px);
    font-size: calc(10px + 1.5vmin);
    outline: none;
    bottom: 0;
}

.Item-guide_Non-safari {
    top: 0;
    transform: rotateX(180deg);
}


.Product-item h1 {
    transition: transform .2s ease-in-out .3s, font-size .1s ease-in-out .4s;
    transform: translateY(-30px);
    font-size: 0px;
}

.Product-item-Cover-Hover h1 {
    transform: none;
    font-size: calc(10px + 1vw + .7vh);
}

.Product-button {
    position: absolute;
    height: calc(40px + 8.5vw + 6vh);
    width: calc(60px + 8.5vw + 6vh);
    border-radius: 50%;
    overflow: visible;
    display: flex;
    color: rgb(6, 50, 104);
    align-items: flex-start;
    padding-top: calc(10px + 2vw + 1vh);
    z-index: 5;
    bottom: calc(0% - 5vw - 3.5vh);
    transition: box-shadow .4s ease-in, background-color .4s ease-out .1s, color .3s ease-out .1s;
    cursor: pointer;
    font-size: calc(20px + 1vw + 1vh);
    background-color: #47b5ff9b;
    box-shadow: none;
    transition: transform .5s ease-in .1s, box-shadow .5s ease-out .2s, color .3s ease-in-out .2s;
}

/* box-shadow: #47B5FF 0px 0px 15px 6px, #47b5ffbf 0px 0px 40px 6px inset, white 0px 0px 5px 1px, rgba(255, 255, 255, 0.679) 0px 0px 30px 5px inset; */


.Product-button:hover {
    background-color: #47B5FF;
    box-shadow: rgba(0, 107, 178, 0.5) 0px 0px 10px 1px, rgb(230, 242, 253) 0px 0px 30px 5px inset, rgba(255, 255, 255, 0.7) 0px 0px 70px 20px inset, #47b5ffd9 0px 0px 50px 5px, #0199fe 0px 0px 10px 5px inset;
    color: rgba(255, 255, 255, 0.9);
}

.Product-button:active {
    background-color: #47B5FF;
    box-shadow: rgb(240, 249, 255) 0px 0px 10px 2px, rgb(230, 242, 253) 0px 0px 7px 3px inset, rgba(255, 255, 255, 0.7) 0px 0px 50px 10px inset, rgba(99, 164, 255) 0px 0px 50px 10px, rgb(118, 221, 255) 0px 0px 10px 5px inset;
    transform: scale(1.1);
    transition: all .1s ease-out, color .2s ease-out;
    color: #1363DF;
}



.Left-button {
    left: calc(-3.5vh - 55vw);
    padding-right: calc(15px + 2.5vw + 1.5vh);
    justify-content: right;
    transform: translateX(-10vw);
}

.Right-button {
    right: calc(-3.5vh - 55vw);
    padding-left: calc(15px + 2.5vw + 1.5vh);
    justify-content: left;
    transform: translateX(10vw);
}

.Product-type-Active .Product-button {
    transform: none;
}

.Product-dot-slider {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: calc(83vw - 12vh - 120px);
    height: calc(3.5vw + 2.5vh + 40px);
    position: absolute;
    bottom: 0;
    z-index: 15;
}

.Product-dot-slider>.Dot-slider {
    height: 15px;
    width: 15px;
    border: 2px solid #1363DF;
    border-radius: 50%;
    cursor: pointer;
    transition: border .5s ease-in-out, background-color .3s ease-out .2s, opacity .3s ease-in-out .2s;
    margin: 5% 2%;
    opacity: 1;
}

.Product-dot-slider>.Dot-slider-Active {
    border: 3px solid #47B5FF;
    background-color: #47B5FF;
}

.Product-Active {
    height: 100%;
    width: 100%;
    padding: 5vh 5vw;
    display: flex;
    flex-flow: row;
    background-color: #47b5ff9b;
    animation: growUp .3s ease-in-out;
    cursor: pointer;
    position: relative;
}

@keyframes growUp {
    from {
        transform: scale(.6) translateY(-100%);
        opacity: 0;
    }

    to {
        transform: scale(1) translateY(0);
        opacity: 1;
    }
}

.Product-Active-image {
    max-height: 100%;
    height: 500px;
    width: 500px;
    max-width: 30%;
    object-fit: scale-down;
}

.Product-Active-text {
    flex-grow: 1;
    padding: 0px 20px;

}

.Product-Active-name {
    color: rgb(0, 33, 105);
    margin-bottom: 20px;
    text-indent: 10%;
    width: 100%;
    text-align: left;
    font-size: calc(12px + .6vh + .7vw);
    text-indent: 10%;
}

.Product-Active-detail {}

.Product-Active-detail-Mask {}

.Product-Active-detail-Paragraph {
    color: white;
    text-align: justify;
    margin-bottom: 20px;
    font-size: calc(10px + .5vh + .7vw);
    text-indent: 10%;
}

.Product-Active-guide {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: underline;
    font-size: calc(12px + 1.5vmin);
    font-weight: 500;
    position: absolute;
    bottom: 0;
    padding-bottom: 2%;
}

@media only screen and (max-width: 480px) {
    .Product-item-Cover-Hover h1 {
        transform: none;
        font-size: calc(20px + .7vw + .5vh);
    }

    .Product-button {
        height: calc(20px + 8vw + 6vh);
        width: calc(30px + 8vw + 6vh);
        align-items: center;
        padding-top: 0;
        bottom: 30%;
        font-size: 30px;
        background-color: #47b5ff56;
        color: white;
    }

    .Product-button:hover {
        background-color: #47b5ff56 !important;
        box-shadow: 0px 0px 0px transparent;
    }

    .Product-button:active {
        box-shadow: rgba(0, 107, 178, 0.5) 0px 0px 10px 1px, rgb(230, 242, 253) 0px 0px 30px 5px inset, rgba(255, 255, 255, 0.7) 0px 0px 70px 20px inset, #47b5ffd9 0px 0px 50px 5px, #0199fe 0px 0px 10px 5px inset;
    }

    .Left-button {
        left: calc(-54vw - 15px - 3vh);
        padding-right: 15px;
        justify-content: right;

    }

    .Right-button {
        right: calc(-54vw - 15px - 3vh);
        padding-left: 15px;
        justify-content: left;

    }

    .Product-dot-slider {
        width: 100vw;
        height: 20%
    }

    .Product-dot-slider>.Dot-slider {
        height: 10px;
        width: 10px;
        border: 1px solid #1363DF;
        margin: 5px;

    }

    .Product-dot-slider>.Dot-slider-Active {
        border: 1px solid #47B5FF;
    }

    .Product-Active {
        padding: 2vh 1vw;
        flex-flow: column;
        background-color: #47b5ff9b;
        animation: growUp .4s ease-in-out;
        cursor: pointer;
    }

    .Product-Active-image {
        max-height: 20%;
        max-width: 100%;
    }

    .Product-Active-text {
        flex-grow: 1;
        padding: 0px 20px;

    }

    .Product-Active-name {
        color: rgb(0, 33, 105);
        margin-bottom: 20px;
        text-indent: 10%;
        width: 100%;
        text-align: left;
        font-size: calc(10px + .6vh + .7vw);
        text-indent: 10%;


    }

    .Product-Active-detail-Paragraph {
        color: white;
        text-align: justify;
        margin-bottom: 20px;
        font-size: calc(10px + .5vh + .7vw);
        text-indent: 10%;
    }
}

@media only screen and (max-width: 780px) and (min-width: 481px) {
    .Product-item-Cover {
        /* pointer-events: none; */
    }

    .Product-button {
        height: calc(30px + 8vw + 6vh);
        width: calc(40px + 8vw + 6vh);
        align-items: center;
        padding-top: 0;
        bottom: 40%;
        font-size: 40px;
        background-color: #47b5ff56;
        color: white;
    }

    .Product-button:hover {
        background-color: #47b5ff56 !important;
        box-shadow: 0px 0px 0px transparent;
    }

    .Product-button:active {
        box-shadow: rgba(0, 107, 178, 0.5) 0px 0px 10px 1px, rgb(230, 242, 253) 0px 0px 30px 5px inset, rgba(255, 255, 255, 0.7) 0px 0px 70px 20px inset, #47b5ffd9 0px 0px 50px 5px, #0199fe 0px 0px 10px 5px inset;
    }

    .Product-button:active {
        background-color: none !important;
        box-shadow: none !important;
        transform: scale(1) !important;
    }

    .Left-button {
        left: -60vw;
        padding-right: 30px;
        justify-content: right;

    }

    .Right-button {
        right: -60vw;
        padding-left: 30px;
        justify-content: left;

    }

    .Product-dot-slider {
        width: 100vw;
        height: 20%;
    }

    .Product-dot-slider>.Dot-slider {
        height: 10px;
        width: 10px;
        border: 2px solid #1363DF;
        margin: 10px;

    }

    .Product-dot-slider>.Dot-slider-Active {
        border: 2px solid #47B5FF;
    }
}