.Header-Blur-layout-Container {
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: -2;
  display: flex;
  align-items: center;
  justify-content: left;
  transition: opacity .2s ease .4s, height .4s ease-in-out .6s;
  position: absolute;
  opacity: .4;

}

.Header-Blur-layout {
  width: 100vw;
  height: 400%;
  z-index: 91;
  position: absolute;
  background: white url("/HeaderPattern.png") repeat 50% 100%;
  background-size: contain;
  transition: opacity .2s ease-in-out .2s, height .2s ease-in-out .4s;
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  mix-blend-mode: hard-light;
  align-items: center;
}


.Header-Rainbow {
  width: 1400vw;
  height: 100%;
  background: linear-gradient(30deg, rgba(0, 255, 239, 1) 0%, rgba(14, 255, 0, 1) 6%, rgba(249, 255, 0, 1) 11%, rgba(255, 94, 0, 1) 16%, rgba(255, 0, 0, 1) 21%, rgba(235, 0, 255, 1) 24%, rgba(0, 18, 255, 1) 29%, rgba(0, 254, 255, 1) 33%, rgba(0, 255, 55, 1) 38%, rgba(228, 255, 0, 1) 42%, rgba(255, 145, 0, 1) 47%, rgba(255, 0, 0, 1) 52%, rgba(252, 0, 255, 1) 56%, rgba(8, 0, 255, 1) 61%, rgba(0, 31, 255, 1) 65%, rgba(0, 255, 239, 1) 69%, rgba(0, 222, 48, 1) 73%, rgba(134, 255, 0, 1) 77%, rgba(242, 252, 0, 1) 81%, rgba(250, 125, 0, 1) 86%, rgba(255, 0, 0, 1) 90%, rgba(255, 0, 0, 1) 100%);
  animation: rainbowRun 15s ease-in-out 0s infinite;
  filter: brightness(200%);
  position: absolute;
  z-index: 90;
  transition: opacity .2s ease-in-out .2s, height .4s ease-out.2s;
  opacity: 1;
}

@keyframes rainbowRun {
  0% {
    transform: translateX(-1300vw);
    transform: none;
    opacity: 0;
  }

  5% {
    opacity: 1;
  }

  45% {
    opacity: 1;
  }

  50% {
    transform: translateX(0);
    opacity: 0;
  }

  55% {
    opacity: 1;
  }

  95% {
    opacity: 1;
  }

  100% {
    transform: none;
    opacity: .0;
    transform: translateX(-1300vw);
  }
}



.App-header_Blur .Header-Blur-layout {
  opacity: 0;
  background-color: transparent;
  transition: opacity .2s ease-in .2s, height .4s ease-in;
  height: 0;
}

.App-header_Blur .Header-Rainbow {
  transition: opacity .2s ease-out .4s, height .2s ease .4s;
  height: 0;
  background: transparent;
  opacity: 0;
}

.App-header_Blur>.Header-Blur-layout-Container {
  height: 0;
  opacity: 0;
  transition: opacity .2s ease-out .6s, height .3s ease .3s;

}

.Header-button {
  font-size: calc(10px + 2vmin);
  width: 120px;
  height: 100%;
  overflow-y: visible;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  position: relative;
}


.Header-button-active>.Button-icon {
  background-color: #3b89ffad;
  max-height: 90%;
  max-width: 40%;
  height: 60px;
  width: 60px;
  color: white;
  border: double .5px rgba(245, 245, 245, 0.2);
  box-shadow: rgba(245, 245, 245, 0.7) 0px 0px 3px 1px, #599afbc0 0px 0px 8px 5px, #438af5d0 0px 0px 20px 5px, #1365dfbc 0px 0px 10px 10px inset, white 0px 0px 30px -1px inset;
}

.App-header_Blur .Button-icon {
  background-color: rgba(255, 255, 255, 0);
  opacity: 0;
  transition: background-color .4s ease-in-out .3s;
}

.Button-icon {
  transition: opacity .5s ease-out .4s, height 0.4s ease-in-out 0s, width .4s ease-in-out 0s, color .7s ease-out 0s, background-color .6s ease-out .1s, box-shadow .5s ease-out .2s;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  color: #1365df;
  opacity: 1;
  z-index: 99;

  box-shadow: rgba(60, 64, 67, 0.3) 0px 0px 3px 1px, rgba(60, 64, 67, 0.15) 0px 0px 3px 1px;

}

.Button-menu {
  font-size: calc(14px + 1.2vmin);
  background-color: #1365df21;
  top: calc(4vh + 20px);
  position: absolute;
  display: flex;
  flex-flow: column;
  color: rgb(255, 255, 255);
  text-shadow: 0px 1px 5px rgba(0, 24, 52, 0.9);
  backdrop-filter: blur(5px) contrast(90%) brightness(95%);
  z-index: 99;
}

.Button-menu-container {
  flex-grow: 1;
  padding: 15px;
}

.Button-menu-Outside {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 98, ;
}

.App-menu>.Button-menu {
  width: 400px;
  max-width: 60vw;
  height: calc(96vh - 20px);
  left: 0;
  animation: displayFromLeft .2s ease-out;
  box-shadow: #3f3f3f2a 3px 3px 5px 1px, #1365df44 5px 3px 10px 0px, #77abff7a 10px 4px 20px 0px, #c5f5ff3a 0px 5px 20px -1px inset;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, .1), #1365df43);

}

@keyframes displayFromLeft {
  from {
    left: -450px;
  }

  to {
    left: 0
  }
}



.Menu-item {
  padding: 10px 20px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
  cursor: pointer;
  transition: color .4s ease-out, background-color .25s ease-in .1s;

}

.Menu-item:hover {
  color: #06283D;
  background-color: whitesmoke;
  text-shadow: none;

}

.Menu-item:active {
  transition: all .2s ease-in-out;
  color: #1365df;
  background-color: #86abe1;
}

.Menu-item-Container:nth-child(2)>.Menu-item:hover {
  cursor: default;
  background-color: transparent;
}

.Menu-item-Container:nth-child(2)>.Menu-item:active {
  color: #06283D;
  background-color: transparent;
}

.Menu-active {
  font-weight: 900;
  backdrop-filter: blur(10px) contrast(40%) brightness(150%);
  box-shadow: 0px 2px 4px 1px #053655c1;
}


.Menu-sub-item-Container {
  height: fit-content;
  width: 100%;
  transition: height .4s ease-out;
  overflow: visible;
  padding: 5px 0;
}

.Menu-sub-item {
  margin-left: 50px;
  padding: 5px 10px;
  text-align: start;
  cursor: pointer;
  list-style-type: circle;
  transition: color .5s ease-out, background-color .4s ease-in .1s;
  color: white;
}

.Menu-sub-item:hover {
  color: #06283D;
  background-color: whitesmoke;
}

.Menu-sub-item:active {
  transition: all .2s ease-in-out;
  color: #1365df;
  background-color: #86abe1;

}

.Menu-sub-active {
  list-style-type: disc;
  font-weight: 900;
  backdrop-filter: blur(50px) brightness(80%);
  box-shadow: 0px 2px 4px 1px #053655c1;

}

.App-languages>.Button-menu {
  width: 250px;
  max-width: 30vw;
  right: 0;
  animation: displayFromRight .1s ease-out;
  box-shadow: #3f3f3f2a -3px 3px 5px 1px, #1365df44 -5px 5px 10px 0px, #77abff7a -10px 10px 20px 0px, #c5f5ff3a 0px 0px 20px -1px inset;
  background-image: linear-gradient(to left, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, .1), #1365df43);

}

@keyframes displayFromRight {
  from {
    right: -230px;
  }

  to {
    right: 0;
  }
}

.Lang-option {
  padding: 5px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: color .5s ease-out, background-color .4s ease-in .1s;
}

.Lang-option:hover {
  color: #06283D;
  background-color: whitesmoke;
}

.Lang-option:active {
  transition: all .2s ease-in-out;
  color: #1365df;
  background-color: #86abe1;
}

.Lang-active::after {
  content: "✓"
}

.Lang-active {
  font-weight: 900;
  backdrop-filter: blur(30px) brightness(120%);
  box-shadow: #3f3f3f2a -3px 3px 5px 1px, #1365df44 -5px 5px 10px 0px, #77abff7a -10px 10px 20px 0px, #c5f5ff3a 0px 0px 20px -1px inset;
}

.Menu-pattern-container {
  height: 15vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px -1px 4px 2px #053655c1 inset;
  opacity: .8;
  background-color: #0536555e;


}

.Menu-pattern {
  height: 100%;
  width: 100%;
  background: url("/MenuPattern.jpg") no-repeat 50% 50%;
  filter: contrast(120%);
  mix-blend-mode: hard-light;


  background-size: cover;
  animation: rotate360 1.3s ease-in .2s;
  /* animation: changeColor 1.3s ease-in-out; */


}

@keyframes changeColor {
  /* 1, 2, 3, 5, 8, 13, 21, 34, 55,  */

  0% {
    mix-blend-mode: hard-light;
  }

  45% {
    mix-blend-mode: color-dodge;
  }

  66% {
    mix-blend-mode: difference;

  }

  79% {
    mix-blend-mode: color-burn;

  }

  87% {
    mix-blend-mode: difference;
  }

  92% {
    mix-blend-mode: luminosity;

  }

  100% {
    mix-blend-mode: hard-light;


  }
}

@keyframes rotate360 {
  0% {
    transform: rotate(0deg);
    height: 600px;
    width: 600px;
    mix-blend-mode: hard-light;
  }

  20% {
  }

  35% {
    transform: rotate(360deg);
    mix-blend-mode: color-dodge;


  }

  40% {
    transform: rotate(135deg);
    mix-blend-mode: difference;


  }

  60% {
    height: 600px;
    width: 600px;
    transform: rotate(45deg) translateY(0%);
    mix-blend-mode: color-dodge;

  }

  70% {
    transform: rotate(-15deg) translateY(-15%);
    mix-blend-mode: color-burn;
  }

  79% {
    mix-blend-mode: difference;

  }
 
  95% {
    height: 100%;
    width: 100%;
    transform: translateY(5%);
    mix-blend-mode: luminosity;

  }

  100% {
    transform: translateY(0);
    mix-blend-mode: hard-light;


  }

}

@media only screen and (max-width: 300px) {
  .App-menu>.Button-menu {
    width: 100vw;
    max-width: 100vw;
  }

  .Menu-sub-item-Container {
    padding: 0px;
  }

  .Menu-sub-item {
    margin-left: 20px;
    padding: 5px 0px;
  }

  .App-languages>.Button-menu {
    width: 75vw;
    max-width: 100vw;
  }
}

@media only screen and (max-width: 480px) and (min-width: 301px) {


  .App-menu>.Button-menu {
    width: 75vw;
    max-width: 100vw;
  }

  .Menu-sub-item-Container {
    padding: none;
  }

  .Menu-sub-item {
    margin-left: 30px;
    padding: 0px 10px;
    margin-bottom: 10px;
  }

  .Menu-sub-item:last-child {
    margin-bottom: 0;
  }

  .App-languages>.Button-menu {
    max-width: 60vw;
  }
}

@media only screen and (max-width: 798px) and (min-width: 481px) {


  .App-languages>.Button-menu {
    max-width: 40vw;
  }
}